import React from 'react'

import { 
  Link, 
  useTheme,
} from "@mui/material";

const InstallMetamask = () => {
  const theme = useTheme();
  return (
    <Link
      href="https://metamask.io/download/"
      color={theme.palette.primary.dark}
      target="metamask.io"
    >Install Metamask</Link>
  )
}

export default InstallMetamask