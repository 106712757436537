import React from 'react'

const Ethereum = () => {
  return (
    <svg width="1920" height="1920" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" style={{ maxHeight: '100%', maxWidth: '100%' }}>
      <path fill="#8A92B2" d="M959.8 80.7L420.1 976.3 959.8 731z"></path>
      <path fill="#62688F" d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"></path>
      <path fill="#454A75" d="M959.8 1295.4l539.8-319.1L959.8 731z"></path>
      <path fill="#8A92B2" d="M420.1 1078.7l539.7 760.6v-441.7z"></path>
      <path fill="#62688F" d="M959.8 1397.6v441.7l540.1-760.6z"></path>
    </svg>
  );
}

export { Ethereum };