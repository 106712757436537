import React from 'react'

const RailsNetwork = () => {
  return (
    <svg
      style={{ maxHeight: '100%', maxWidth: '100%' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 914.23 156.96"
    >
      <defs>
        <style>{".cls-1,.cls-2{fill:#b9cad8;}.cls-1{fill-rule:evenodd;}"}</style>
      </defs>
      <g id="layer-2" data-name="layer-2">
        <g id="layer-1" data-name="layer-1">
          <path className="cls-1" d="M0,.23,107.9,76.28V97.71l-88.67-66-.35.18v88.63L38.2,105.31,72,127.43l-26.57-2.77L.94,156.45H0Z"/>
          <path className="cls-1" d="M135.58,0q.16,78.47.34,157L28.4,80.55q.18-10.89.35-21.78l.69.7,88.16,65.34V36L101.37,48.2,68.85,32v-.54l27-2.76Z"/>
          <path className="cls-2" d="M200.21,115.43,187.68,96.85H173.85v18.58H159.56V49.94h26.73a32.46,32.46,0,0,1,14.24,2.9,21.55,21.55,0,0,1,9.31,8.23A23.79,23.79,0,0,1,213.1,73.7a23.24,23.24,0,0,1-3.3,12.58,21.27,21.27,0,0,1-9.4,8.1l13.85,21.05Zm-4.54-49.88c-2.27-1.94-5.6-2.91-10-2.91H173.85V85.13h11.86c4.36,0,7.69-1,10-2.95s3.41-4.73,3.41-8.3S198,67.48,195.67,65.55Z"/>
          <path className="cls-2" d="M263.94,101.74H235l-5.51,13.69H213.88l28.45-65.49h14.59l28.54,65.49h-16ZM259.58,90l-10-24.88L239.49,90Z"/>
          <path className="cls-2" d="M288.16,49.94h14.29v65.49H288.16Z"/>
          <path className="cls-2" d="M313.88,49.94h15.24v52.78h31.44v12.71H313.88Z"/>
          <path className="cls-2" d="M371.47,114.1a33.57,33.57,0,0,1-11.26-5.43l5-11.33a33.48,33.48,0,0,0,9.68,4.92,35.47,35.47,0,0,0,11.22,1.86q6.24,0,9.23-1.91a5.76,5.76,0,0,0,3-5.06,4.88,4.88,0,0,0-1.77-3.85A13.23,13.23,0,0,0,392,90.84q-2.76-.93-7.47-2a102.4,102.4,0,0,1-11.85-3.53,19.28,19.28,0,0,1-7.92-5.67q-3.3-3.9-3.3-10.4a18.46,18.46,0,0,1,3-10.26,20.22,20.22,0,0,1,9-7.29A36,36,0,0,1,388.17,49,47.65,47.65,0,0,1,400,50.45a34.36,34.36,0,0,1,10.13,4.27l-4.52,11.42A34.64,34.64,0,0,0,388.08,61q-6.15,0-9.09,2A6.28,6.28,0,0,0,376,68.46a5.27,5.27,0,0,0,3.4,5,52.21,52.21,0,0,0,10.36,3.2,100.46,100.46,0,0,1,11.85,3.53,19.61,19.61,0,0,1,7.92,5.57q3.3,3.81,3.31,10.31a18,18,0,0,1-3,10.17,20.52,20.52,0,0,1-9.09,7.29A36.33,36.33,0,0,1,386,116.19,50.75,50.75,0,0,1,371.47,114.1Z"/>
          <path className="cls-2" d="M499.64,49.94v65.49H487L455.82,75.67v39.76H441.53V49.94h12.74L485.35,89.7V49.94Z"/>
          <path className="cls-2" d="M559.65,102.72v12.71H511.07V49.94H558.7V61.67H525.36V76.33h29.53V88.06H525.36v14.66Z"/>
          <path className="cls-2" d="M581.56,62.64h-20V49.94H616.8v12.7H595.85v52.79H581.56Z"/>
          <path className="cls-2" d="M715.6,49.94l-20.88,65.49H678.86l-14-44.35-14.5,44.35H634.54l-21-65.49h15.32L643.3,96l15-46H672l14.59,46.4,14.86-46.4Z"/>
          <path className="cls-2" d="M732,111.82a31.78,31.78,0,0,1-12.4-12,33.47,33.47,0,0,1-4.48-17.23,33.46,33.46,0,0,1,4.48-17.22,31.88,31.88,0,0,1,12.4-12,38.35,38.35,0,0,1,35.52,0,32.33,32.33,0,0,1,12.39,12,33.2,33.2,0,0,1,4.53,17.22,33.21,33.21,0,0,1-4.53,17.23,32.23,32.23,0,0,1-12.39,12,38.27,38.27,0,0,1-35.52,0Zm27.91-11.09A18.84,18.84,0,0,0,767,93.35a23.79,23.79,0,0,0,0-21.55,18.77,18.77,0,0,0-7.11-7.38,20.71,20.71,0,0,0-20.27,0,18.82,18.82,0,0,0-7.1,7.38,23.79,23.79,0,0,0,0,21.55,18.88,18.88,0,0,0,7.1,7.38,20.77,20.77,0,0,0,20.27,0Z"/>
          <path className="cls-2" d="M832.73,115.43,820.2,96.85H806.37v18.58H792.08V49.94h26.73a32.52,32.52,0,0,1,14.24,2.9,21.55,21.55,0,0,1,9.31,8.23,23.79,23.79,0,0,1,3.26,12.63,23.16,23.16,0,0,1-3.31,12.58,21.19,21.19,0,0,1-9.39,8.1l13.85,21.05Zm-4.54-49.88q-3.42-2.91-10-2.91H806.37V85.13h11.85q6.56,0,10-2.95a10.4,10.4,0,0,0,3.41-8.3Q831.6,68.45,828.19,65.55Z"/>
          <path className="cls-2" d="M877.58,89.7l-8.34,9.17v16.56H855V49.94h14.29V80.53l27.51-30.59h16L887,79.13l27.24,36.3H897.46Z"/>
        </g>
      </g>
    </svg>
  );
}

export { RailsNetwork };