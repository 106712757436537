import React from 'react';

import {
  Box,
  useTheme,
} from "@mui/material";
import { copyrightSymbol, tmSymbol } from 'components/symbols';

const SidebarFooter = () => {
  const theme = useTheme();

  const styles = {
    display: 'flex',
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
    padding: '10px 20px', // Reduced padding for a sleeker appearance
    whiteSpace: 'normal', // Allow text to wrap within the sidebar
    textAlign: 'center', // Center the text horizontally within the flex container
    fontSize: '11px', // Slightly reduce font size for a more subtle look
    color: theme.palette.steamx.white,
  };

  return (
    <Box sx={styles}>
      Powered by Rails Network{tmSymbol}<br />
      {copyrightSymbol} 2023. All Rights Reserved. Steam Exchange Inc{tmSymbol}
    </Box>
  );
};

export default SidebarFooter;
