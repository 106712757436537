import { createSlice } from "@reduxjs/toolkit";

const disconnectedState = {
  account: null,
  balance: "",
  chainId: "",
  // metamask: {
  //   hasProvider: false,
  //   error: false,
  //   errorMessage: "",
  //   isConnecting: false,
  // },
};
const initialState = {
  mode: "dark",
  wallet: disconnectedState,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setWallet: (state, action) => {
      state.wallet = {
        account: action.payload.account,
        balance: action.payload.balance,
        chainId: action.payload.chainId,
      };
    },
  },
});

export const { setMode, setWallet } = authSlice.actions;

export default authSlice.reducer;
