import React from 'react';

import {
  Box,
} from "@mui/material";
import { RailsNetwork } from 'components/icons/RailsNetwork';


const SidebarHeader = () => {

  const styles = {
    display: 'flex',
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
    padding: '20px', // Add padding for spacing
  };

  return (
    <Box sx={styles}>
      <RailsNetwork />
    </Box>
  )
}

export default SidebarHeader;
