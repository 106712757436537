import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  // Modal,
  // Paper,
  Typography,
  useTheme,
} from "@mui/material";

import { ConfirmDialog } from './ConfirmDialog';
import { formatBalance, shortenAddress } from 'helpers';
import { ethers } from "ethers";
import { setWallet } from "state";

import InstallMetamask from './InstallMetamask';

const ConnectButton = () => {

  const theme = useTheme();
  if (!window.ethereum){
    console.log('Please install MetaMask');
    return ( <InstallMetamask /> );
  }

  const [account, _setAccount] = useState(null);
  const [previousAccount, setPreviousAccount] = useState(null);
  const accountRef = useRef(account);
  const setAccount = (account) => { 
    accountRef.current = account;
    _setAccount(account); 
  }

  const [balance, _setbalance] = useState('0.0');
  const balanceRef = useRef(balance);
  const setBalance = (balance) => {
    balanceRef.current = balance;
    _setbalance(balance);
  }

  const dispatch = useDispatch();
  const [isCheckingConnection, setIsCheckingConnection] = useState(false);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const [isDisconnectButtonClicked, setIsDisconnectButtonClicked] = useState(false);

  const [network, setNetwork] = useState('');
  const [symbol, setSymbol] = useState('');

  const buttonStyle = {
    fontSize: '14px',
    backgroundColor: theme.palette.steamx.orange,
    color: theme.palette.steamx.white,
    borderRadius: '6px',
    fontWeight: 'bold',
    padding: '8px 16px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.background.alt,
    }
  }

  const checkConnection = async () => {
    if (isCheckingConnection){ return; }
    setIsCheckingConnection(true);
    try {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' })
      await handleAccountsChanged(accounts);
    } catch (error){
      console.error(error);
    } finally {
      setIsCheckingConnection(false);
    }
  }

  const getBalance = async (account) => {
    try {
      const provider = await new ethers.BrowserProvider(window.ethereum);
      const balance = await provider.getBalance(account);
      return ethers.formatEther(balance);
    } catch (e) {
      return '';
    }
  }

  const handleAccountsChanged = async (accounts) => {
    if (!accounts || accounts.length === 0){ 
      updateValues(null, "", "");
      return;
    }
    const account = accounts[0];
    const balance = await getBalance(account);
    const chainId = await window.ethereum.request({
      method: 'eth_chainId',
    })
    updateValues(account, balance, chainId);
    setPreviousAccount(null);
  }

  const handleChainChanged = async (chainId) => {
    const account = accountRef.current;
    const balance = await getBalance(account)
    updateValues(account, balance, chainId);
  }

  const handleConnectRequest = async() => {
    try {
      // use this to always show the Metamask UI, even after dApp disconnect 
      // (which does not actually disconnect Metamask)
      // see: https://github.com/MetaMask/metamask-extension/issues/8990
      await window.ethereum.request({ 
        method: 'wallet_requestPermissions', 
        params: [{
          eth_accounts: {},
        }]
      })
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      // the 'accountChanged' event is not triggered
      if (accounts[0] === previousAccount){
        handleAccountsChanged(accounts)
      }

    } catch(error){
      switch(error.code){
        case -32002: { 
          console.error('A request to connect your wallet is already pending');
          break;
        }
        case 4001:
        default: {
          console.error('Error connecting wallet: ', error);
          break;
        }
      }
    }
  }

  const handleDisconnect = async() => {
    setPreviousAccount(account);
    setIsDisconnectButtonClicked(true);
    setIsDisconnectModalOpen(true);
  }
  const handleDisconnectConfirmation = async() => {
    setIsDisconnectModalOpen(false);
    if (isDisconnectButtonClicked) {
      try {
        await window.ethereum.request({ method: 'eth_accounts' });
        updateValues(null, "", "");
      }
      catch (error){
        console.log('Error disconnecting wallet');
        console.error(error);
      }
    }
    setIsDisconnectButtonClicked(false);
  }

  const updateValues = async (account, balance, chainId) => {
    setAccount(account);
    setBalance(balance);
    switch (chainId){
      case      '0x1' : { setSymbol("ETH"); setNetwork("Ethereum"); break; }
      case     '0x38' : { setSymbol("BNB"); setNetwork("BSC"); break; }
      case     '0x61' : { setSymbol("tBNB"); setNetwork("BSC Testnet"); break; }
      case     '0x89' : { setSymbol("MATIC"); setNetwork("Polygon"); break; }
      case   '0x1886' : { setSymbol("STEAMX"); setNetwork("Rails"); break; }
      case   '0x5e34' : { setSymbol("STEAMX"); setNetwork("Rails Testnet"); break; }
      case  '0x13881' : { setSymbol("tMATIC"); setNetwork("Polygon PoS Mumbai"); break; }
      case '0xaa36a7' : { setSymbol("ETH"); setNetwork("Sepolia"); break; }
      default         : { setSymbol("???"); setNetwork("???"); }
    }    
    dispatch(setWallet({
      account: account,
      balance: balance,
      chainId: chainId,
    }));
  }

  const closeDisconnectModal = () => {
    setIsDisconnectModalOpen(false);
  }

  useEffect(() => {
    checkConnection();

    window.ethereum.on('accountsChanged', handleAccountsChanged);
    window.ethereum.on('chainChanged', handleChainChanged);
    return () => {
      window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      window.ethereum.removeListener('chainChanged', handleChainChanged);
    }
  }, []);

  return (
    <Box sx={{
      background: `linear-gradient(135deg, ${theme.palette.background.alt} 0%, ${theme.palette.steamx.light} 100%)`,
      padding: '16px',
      borderRadius: '12px',
      textAlign: 'center',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      margin: '20px',
      position: 'relative',
    }}>
      { account ? (
        <Box>
          <Typography variant="h6" style={{ 
            fontWeight: 'bold', 
            color: theme.palette.steamx.white, 
            marginBottom: '8px'
          }}>
            {shortenAddress(account)}
          </Typography>

          {network && <Typography variant="subtitle2" style={{ 
            color: theme.palette.steamx.white, 
            marginBottom: '8px' 
          }}>
            Chain: {network}
          </Typography>}
          <Typography variant="subtitle1" style={{ 
            color: theme.palette.steamx.white, 
            marginBottom: '16px' 
          }}>
            <abbr title={balance} style={{textDecoration:"none"}}>
              {formatBalance(balance)} {symbol}
            </abbr>
          </Typography>
          <Button onClick={handleDisconnect} sx={buttonStyle}>
            Disconnect
          </Button>
        </Box>
      ) : (
        <Box>
          {isCheckingConnection ? (
            <Typography variant="subtitle1" style={{ 
              color: theme.palette.steamx.white 
            }}>connecting ...</Typography>
          ) : (
            <Button
              fullWidth
              onClick={handleConnectRequest}
              variant="contained"
              sx={buttonStyle}
            >Connect Wallet</Button> 
          )}
        </Box>
      )}

      <ConfirmDialog 
        cancelText="Cancel"
        cancelOnClick={closeDisconnectModal}
        confirmText="Disconnect"
        confirmOnClick={handleDisconnectConfirmation}
        onClick={closeDisconnectModal}
        open={isDisconnectModalOpen}
        prompt="Are you sure you want to disconnect your wallet?"
      />
    </Box>
  )
}

export default ConnectButton;