import React, {
  useEffect,
  useState,
} from 'react';
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Table, TableBody, TableRow, TableCell,
  useTheme,
} from '@mui/material';
import {
  CheckBoxOutlineBlank,
  CheckBox,
} from '@mui/icons-material';
import { ethers } from "ethers";

import { BusyDialog,} from "./bridge/index";
import { CollapsibleAlert } from "./CollapsibleAlert";
import { FlexBetween, FlexCenter, Modal } from './common';
import { MigrateBox } from './migrate/MigrateBox';

import { shortenBalance } from "helpers";
import {
  NETWORK_RAILS,
  NETWORK_BSC,
  HEALTH_CHECK_TOLERANCE,
  // NETWORK_RAILS_TESTNET,
  // NETWORK_BSC_TESTNET,
} from "config";

const STEAMX_DECIMALS = 9;

const Migrate = () => {

  const theme = useTheme();
  const wallet = useSelector((state) => state.wallet);
  const chainEnv = NETWORK_BSC;
  const chainRailsEnv = NETWORK_RAILS;
  // const chainEnv = NETWORK_BSC_TESTNET;
  // const chainRailsEnv = NETWORK_RAILS_TESTNET;
  
  const [busyDialogOpen, setBusyDialogOpen] = useState(false);
  const [healthy, setHealthy] = useState(false);

  const [alertText, setAlertText] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");
  const [isAlertDisplayed, setIsAlertDisplayed] = useState(false);
  
  const [balance, setBalance] = useState(0);
  const [steamxBalance, setSteamxBalance] = useState("");
  const [railsBalance, setRailsBalance] = useState("");
  const [allowance, setAllowance] = useState(0);
  
  const [isMigrationInitialized, setIsMigrationInitiated] = useState(false);
  const [isMigrationComplete, setIsMigrationComplete] = useState(false);

  const [isMigrationModalOpen, setIsMigrationModalOpen] = useState(false);

  const buttonStyles = {
    fontSize: '12px',
    backgroundColor: theme.palette.steamx.orange,
    color: theme.palette.steamx.white,
    borderRadius: '6px',
    margin: '0 0.5em',
    padding: '6px 12px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.steamx.light,
    }
  }

  const checkboxStyles = {
    color: theme.palette.steamx.orange,
  }
  const checkboxOutlineStyles = {
    color: theme.palette.steamx.light, 
    marginTop: '0.2em',
  }
  const modalBoxTableRowStyles = {
    marginBottom: '6px',
    borderBottom: `2px solid ${theme.palette.steamx.light}`,
    paddingBottom: '6px'
  }
  const modalBoxBalanceStyles = {
    textAlign: 'right',
    color: theme.palette.steamx.orange
  }

  // const onConnect = async () => {
  //   await window.ethereum.request({
  //     method: 'wallet_switchEthereumChain',
  //     params: [{ chainId: "0x" + parseInt(chainEnv.id).toString(16) }]
  //   })
  // }

  const checkHealth = async() => {

    if (!fetch){
      showAlert('error', 'fetch API not supported');
    }
    if (!chainEnv.healthcheck){
      showAlert('error', 'Migration healthcheck not supported');
      setHealthy(true);
      return true;
    }
    try {
      const response = await fetch(chainEnv.healthcheck);
      if (response.ok){
        const data = await response.json();
        const providerFrom = new ethers.JsonRpcProvider(chainEnv.rpc);
        const providerTo = new ethers.JsonRpcProvider(chainRailsEnv.rpc);
        const blockNumberDifferenceFrom = await providerFrom.getBlockNumber() - data[chainEnv.short];
        const blockNumberDifferenceTo = await providerTo.getBlockNumber() - data[chainRailsEnv.short];
        if (blockNumberDifferenceFrom < HEALTH_CHECK_TOLERANCE && blockNumberDifferenceTo < HEALTH_CHECK_TOLERANCE){
          setHealthy(true);
          setIsAlertDisplayed(false);
          return true;
        }
      }
    } catch (e){ console.error('Heathcheck failed'); }
    
    showAlert('error', 'Migration is currently offline');
    setHealthy(false);
    return false;
  }

  const checkActiveChainId = async() => {
    try {
      const activeChainId = await window.ethereum.request({
        "method": "eth_chainId",
        "params": [],
      });
      if (chainEnv && chainEnv.id !== parseInt(activeChainId, 16)){
        const chainId = '0x' + parseInt(chainEnv.id).toString(16);
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainId }]
        }).catch(async(e) => {
          console.log(e.message);
        });
      }
    } catch (e){
      console.log(e);
    }
  }

  const startMigration = async() => {
    setIsMigrationModalOpen(true);
  }

  const approve = async() => {
    const isHealthy = await checkHealth();
    if (!isHealthy){ return; }

    await checkActiveChainId();
    if (!(balance > 0)){
      showAlert('error', 'Invalid Balance');
      return;
    }

    const abi = [
      'function approve(address spender, uint256 amount) public returns (bool)'
    ];

    const provider = await new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(chainEnv.steamx.address, abi, signer);

    const extra = 1_000_000_000n;
    try {
      const tx = await contract.approve(chainEnv.migration.address, balance + extra);
      setBusyDialogOpen(true);
      await tx.wait();
    } catch (error){
      console.log(error);
    } finally {
      updateSteamxAllowance(wallet);
      updateSteamxBalance(wallet);
      setBusyDialogOpen(false);
    }
  }

  const confirm = async(wallet) => {
    if (!wallet || !wallet.account){ return; }
    const url = `${chainRailsEnv.explorer}/address/${wallet.account}`;
    const tab = window.open(url, '_blank', 'noopener,noreferrer')
    if (tab){ tab.opener = null; }
  };

  const migrate = async() => {

    const abi = ['function migrate() external'];
    const provider = await new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(chainEnv.migration.address, abi, signer);

    let success = false;
    try {
      const tx = await contract.migrate();
      setBusyDialogOpen(true);
      await tx.wait();
      setIsMigrationInitiated(true);
      success = true;
    } catch (error){
      console.log(error);
    } finally {
      updateSteamxAllowance(wallet);
      updateSteamxBalance(wallet);
      setRailsBalance("...");
      setBusyDialogOpen(false);
    }

    if (success){
      setBusyDialogOpen(true);
      setTimeout(() => {
        updateRailsBalance(wallet);
        setIsMigrationComplete(true);
        setBusyDialogOpen(false);
      }, 15000);
    }
  }

  const showAlert = (severity, text) => {
    setAlertSeverity(severity);
    setAlertText(text);
    setIsAlertDisplayed(true);
    setTimeout(() => {
      setIsAlertDisplayed(false);
    }, 10000);
  }

  const updateRailsBalance = async(wallet) => {
    if (!wallet.account){
      setRailsBalance("");
      return;
    }
    setRailsBalance("...");
    const provider = new ethers.JsonRpcProvider(chainRailsEnv.rpc);
    const balance = await provider.getBalance(wallet.account);
    setRailsBalance(shortenBalance(ethers.formatUnits(balance.toString(), 18)));
  }

  const updateSteamxAllowance = async(wallet) => {
    if (!wallet.account){
      setAllowance(0);
      return 0;
    }
    const abi = [
      'function allowance(address owner, address spender) public view returns (uint256)'
    ];
    const provider = new ethers.JsonRpcProvider(chainEnv.rpc);
    const contract = new ethers.Contract(chainEnv.steamx.address, abi, provider);

    const allowance = await contract.allowance(wallet.account, chainEnv.migration.address);
    setAllowance(allowance);
  }

  const updateSteamxBalance = async(wallet) => {
    if (!wallet.account){ 
      setBalance(0);
      setSteamxBalance("");
      return; 
    }
    setSteamxBalance("...");
    const abi = [
      'function balanceOf(address) view returns (uint256)'
    ];
    const provider = new ethers.JsonRpcProvider(chainEnv.rpc);
    const contract = new ethers.Contract(chainEnv.steamx.address, abi, provider);

    const balance = await contract.balanceOf(wallet.account);
    setBalance(balance);
    setSteamxBalance(shortenBalance(ethers.formatUnits(balance.toString(), STEAMX_DECIMALS)));
  }

  useEffect(() => {
    updateSteamxAllowance(wallet);
    updateSteamxBalance(wallet);
    updateRailsBalance(wallet);
    checkHealth();
  }, [wallet])

  return (
    <Box id="container" width="100%" sx={{ minWidth: '424px', }}>
      <CollapsibleAlert 
        alertSeverity={alertSeverity} 
        alertText={alertText} 
        isAlertDisplayed={isAlertDisplayed}
        setIsAlertDisplayed={setIsAlertDisplayed}
      />
      <FlexCenter maxWidth="800px" p="2em" m="2em auto" textAlign="left" flexDirection="column" gap={4}>
        <MigrateBox 
          account={chainEnv.steamx.address}
          balance={steamxBalance}
          chainEnv={chainEnv}
          currency="SteamX"
          icon="binance"
        />
        {/* <FlexBetween m="2em 0">
          <Button sx={buttonStyles} variant="contained" onClick={approve} disabled={wallet?.account === null}>Approve</Button>
          <Button sx={buttonStyles} variant="contained" onClick={confirm} disabled={!healthy || balance == 0 || allowance < balance}>Migrate</Button>
        </FlexBetween>   */}
    
        <MigrateBox
          account={wallet.account}
          balance={railsBalance}
          chainEnv={chainRailsEnv}
          currency="STEAMX"
          icon="rails"
        />

        <FlexBetween>
          <Button sx={buttonStyles} variant="contained" onClick={startMigration} disabled={!healthy}>Start Migration</Button>
        </FlexBetween>          
      </FlexCenter>

      <Modal
        open={isMigrationModalOpen}
        closeHandler={() => setIsMigrationModalOpen(false)}
      >
        <>
          <Table>
            <TableBody>
              <TableRow sx={modalBoxTableRowStyles}>
                <TableCell>BSC Balance</TableCell>
                <TableCell sx={modalBoxBalanceStyles}>{steamxBalance}</TableCell>
              </TableRow>
              <TableRow sx={modalBoxTableRowStyles}>
                <TableCell>Rails Balance</TableCell>
                <TableCell sx={modalBoxBalanceStyles}>{railsBalance}</TableCell>
              </TableRow>
              <TableRow sx={modalBoxTableRowStyles}>
                <TableCell>Migration Amount</TableCell>
                <TableCell sx={modalBoxBalanceStyles}>{shortenBalance(ethers.formatUnits((BigInt(balance) / 550n).toString(), STEAMX_DECIMALS))}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table>
            <TableBody>
              <TableRow sx={modalBoxTableRowStyles}>
                <TableCell>1. Click Approve</TableCell>
                <TableCell>{allowance > balance ? <CheckBox sx={ checkboxStyles }/> : <CheckBoxOutlineBlank sx={ checkboxOutlineStyles } />}</TableCell>
              </TableRow>
              <TableRow sx={modalBoxTableRowStyles}>
                <TableCell>2. Click Migrate</TableCell>
                <TableCell>{isMigrationInitialized ? <CheckBox sx={ checkboxStyles }/> : <CheckBoxOutlineBlank sx={ checkboxOutlineStyles } />}</TableCell>
              </TableRow>
              <TableRow sx={modalBoxTableRowStyles}>
                <TableCell>3. Migration Successful</TableCell>
                <TableCell>{isMigrationComplete ? <CheckBox sx={ checkboxStyles }/> : <CheckBoxOutlineBlank sx={ checkboxOutlineStyles } />}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <FlexCenter m="2em 0">
            { allowance < balance && (
              <Button sx={buttonStyles} variant="contained" onClick={approve} disabled={wallet?.account === null}>Approve</Button>
            )}
            { allowance >= balance && balance > 0 && (
              <Button sx={buttonStyles} variant="contained" onClick={migrate} disabled={!healthy}>Migrate</Button>
            )}
            { isMigrationComplete && (
              <Button sx={buttonStyles} variant="contained" onClick={() => { confirm(wallet);}} >Confirmation</Button>
            )}
          </FlexCenter>
        </>
      </Modal>
      <BusyDialog open={busyDialogOpen} /> 
    </Box>
  )
};
export default Migrate
