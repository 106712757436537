const { styled } = require("@mui/system");
import { Box } from "@mui/material";
import { InputBox } from "./InputBox";
import { InputTextField } from "./InputTextField";
import { Modal } from "./Modal";

const BackgroundBox = styled(Box)({
  backgroundColor: "rgba(255,255,255,0.05)",
  borderRadius: "0.75em",
});

const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const FlexCenter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

export { BackgroundBox, FlexBetween, FlexCenter, InputBox, InputTextField, Modal };
