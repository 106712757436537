import React from 'react'
import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from 'prop-types';

const InputBox = (props) => {
  const {label, children} = props;
  return (
    <Box p="0.75em">
      <Stack direction="column" width="100%">
        <Typography variant="h6">{label}</Typography>
        {children}
      </Stack>
    </Box>
  );
}
InputBox.propTypes = {
  label: PropTypes.string,
  children: PropTypes.object,
};

export { InputBox };