import React from 'react'
import {
  Backdrop,
  CircularProgress,
  useTheme,
} from "@mui/material";
import PropTypes from 'prop-types';


const BusyDialog = (props) => {
  const { open } = props;
  const theme = useTheme();
  return (
    <Backdrop
      sx={{ color: theme.palette.steamx.orange, zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
BusyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export { BusyDialog };