import React from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { isMobileMediaQuery, shortenAddress } from 'helpers';
import { BackgroundBox } from 'components/common';
import { InputBox, InputTextField } from 'components/common';
import {
  Binance,
  Rails,
  SteamX,
} from '../icons';
import PropTypes from 'prop-types';

const MigrateBox = (props) => {

  const { chainEnv, balance, account, icon, currency } = props;
  const theme = useTheme();

  const isMobile = isMobileMediaQuery();

  const primaryStackDirection = isMobile ? 'column' : 'row';
  const chainBackgroundBoxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.alt,
    width: 160,
    height: 160,
    minWidth: 160,
  };
  const chainBackgroundBoxStyleMobileOverride = {
    width: "100%",
    height: 40,
    minWidth: 320,
  }
  if (isMobile){
    Object.assign(chainBackgroundBoxStyle, chainBackgroundBoxStyleMobileOverride);
  }
  const chainNameMargin = isMobile ? "0" : "0.5em";

  const buttonSx = {
    flexDirection: "column",
    width: "100%",
    height: "100%",
    cursor: "default",
  }

  return (
    <BackgroundBox padding="1.5em" width="100%" sx={{
      backgroundColor: theme.palette.steamx.light,
    }}>
      <Stack direction={primaryStackDirection} gap="0.5em">
        <BackgroundBox sx={chainBackgroundBoxStyle}>
          <Button sx={buttonSx} disabled={true}>
            { !isMobile && icon === 'rails' && ( <Box width="80px" height="80px"><Rails /></Box> ) }
            { !isMobile && icon === 'binance' && ( <Box width="80px" height="80px"><Binance /></Box> ) }
            <Typography variant="h5" color={theme.palette.neutral.medium} mt={chainNameMargin}>{chainEnv.name}</Typography>
          </Button> 
        </BackgroundBox>

        <Stack direction="column" gap="0.5em" width="100%">
          {/* asset box */}
          <BackgroundBox width="100%" height="50%" sx={{
            backgroundColor: theme.palette.background.alt,
          }}>
            <InputBox label="Asset">
              <Stack direction="row" gap="0.5em" width="100%" p="4px 0 5px">
                <Box width="24px" height="24px">
                  { icon === 'rails' && ( <Rails /> ) }
                  { icon === 'binance' && ( <SteamX /> ) }
                </Box>
                <Typography variant="h5">{currency}</Typography>
                <Typography variant="h6" color={theme.palette.neutral.medium}>
                  {chainEnv && `${chainEnv.name}: ${shortenAddress(account)}`}
                </Typography>
              </Stack>
            </InputBox>
          </BackgroundBox>
            <Box width="100%" height="100%" backgroundColor="transparent">
              <InputBox label="Balance">
                <InputTextField placeholder="-" disabled={true} value={balance} />
              </InputBox>
            </Box>
        </Stack>
      </Stack>
    </BackgroundBox>
  )
}

MigrateBox.propTypes = {
  chainEnv: PropTypes.any,
  balance: PropTypes.string,
  account: PropTypes.string,
  icon: PropTypes.string,
  currency: PropTypes.string,
};

export { MigrateBox };