import { 
  useMediaQuery,
} from "@mui/material";

/**
 * 1_000_111_222_001_990_000_000_000_000n => 1,000,111,222.0020
 * 1_000_111_222_000_190_000_000_000_000n => 1,000,111,222.0002
 * 1_000_111_222_039_990_000_000_000_000n => 1,000,111,222.0400
 */
export const formatBalance = (value, decimals = 4) => {
	const parsed = value.split('.');
	const integer = parsed[0];
  var fraction = parsed[1].substring(0, decimals + 1).padEnd(decimals + 1, '0');
  fraction = Math.round(parseInt(fraction)/10.0).toString().substring(0,decimals).padStart(decimals, '0');
  return new Intl.NumberFormat('en-US').format(integer) + '.' + fraction;
}

export function hexId(id) {
  return '0x' + parseInt(id).toString(16);
}

export function isMobileMediaQuery() {
  return !(useMediaQuery("(min-width: 600px"));
}

export function slugify(s) {
  if (!s){ return ''; }
  var slug = s.toLowerCase().trim();
  slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  slug = slug.replace(/[^a-z0-9\s-]/g, ' ').trim();
  slug = slug.replace(/[\s-]+/g, '-');
  return slug;
}

export function shortenAddress(address) {
  return shortenHex(address, 7, 5);
}

export function shortenHash(hash){ return shortenHex(hash, 5, 3); }
export function shortenNonce(nonce) { return shortenHex(nonce, 7, 5); }

export function shortenHex(s, first, last, def = '...') {
  if (!s || s.length < first + last){ return def; }
  const start = s.substring(0, first);
  const end = s.substring(s.length - last);
  return `${start}...${end}`;
}

export function shortenBalance(balance) {
  return Number(balance).toLocaleString("en", { minimumFractionDigits: 6 })
}