import React from 'react';
import {
  Box,
  Button,
  Modal,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from 'prop-types';

const ConfirmDialog = (props) => {

  const theme = useTheme();

  const canceTextDefault = 'Cancel';
  const {cancelOnClick, cancelText, confirmOnClick, confirmText, onClick, open, prompt} = props;

  const buttonStyle = {
    fontSize: '14px',
    backgroundColor: theme.palette.steamx.orange,
    color: theme.palette.steamx.white,
    borderRadius: '6px',
    fontWeight: 'bold',
    padding: '8px 16px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.steamx.light,
    },
  };

  return (
    <Modal
    open={open}
    onClick={onClick}
    aria-labelledby="disconnect-modal-title"
    aria-describedby="disconnect-modal-description"
  >
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
    >
      <Paper sx={{
        background: theme.palette.background.alt,
        padding: '16px',
        textAlign: 'center',
        borderRadius: '12px',
        boxShadow: '0px 6px 12px rgba(0, 0, 0, 1)',
        width: '400px', // Adjust the width as needed
      }}>
        <Typography variant="h6" id="disconnect-modal-title" style={{ 
          fontWeight: 'bold', 
          color: theme.palette.steamx.white, 
          marginBottom: '16px' 
        }}>
          {prompt}
        </Typography>
        <Button
          onClick={confirmOnClick}
          sx={Object.assign({}, buttonStyle, {
            marginRight: '8px',
          })}
        >
          {confirmText}
        </Button>
        <Button
          onClick={cancelOnClick}
          variant="contained"
          sx={Object.assign({}, buttonStyle, {
            backgroundColor: theme.palette.background.default,
          })}
        >
          {cancelText ? cancelText : canceTextDefault}
        </Button>
      </Paper>
    </Box>
  </Modal>
  )
}

ConfirmDialog.propTypes = {
  cancelOnClick: PropTypes.func.isRequired, 
  cancelText: PropTypes.string, 
  confirmOnClick: PropTypes.func.isRequired, 
  confirmText: PropTypes.string.isRequired, 
  onClick: PropTypes.func.isRequired, 
  open: PropTypes.bool.isRequired,
  prompt: PropTypes.string.isRequired,
};

export { ConfirmDialog };