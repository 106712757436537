import React from 'react'
import {
  Dialog,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import PropTypes from 'prop-types';
import { slugify } from 'helpers';


const SelectChainDialog = (props) => {
  const { pairs, onClose, open } = props;
  const list = Object.values(pairs);
  const theme = useTheme();

  const handleClose = () => {
    onClose(null);
  }
  const handleListItemClick = (value) => {
    onClose(value);
  }

  const dialogStyles = {
    backgroundColor: theme.palette.steamx.dark,
    color: theme.palette.steamx.white,
  };
  const listStyles = {
    '&:hover': {
      backgroundColor: theme.palette.steamx.orange
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} sx={{
      '& .MuiDialog-paper': dialogStyles
    }}>
      <DialogTitle sx={dialogStyles}>Select Networks</DialogTitle>
      <List sx={{ pt: 0}}>
        {list.map((pair) => {
          return (
          <ListItem disableGutters key={`${slugify(pair.from.name)}-${slugify(pair.to.name)}`}>
            <ListItemButton onClick={() => handleListItemClick(pair)} sx={listStyles}>
              <ListItemText primary={`${pair.from.name} → ${pair.to.name}`} />
            </ListItemButton>
          </ListItem>
          )
        })}
      </List>
    </Dialog>
  )
}
SelectChainDialog.propTypes = {
  pairs: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedChain: PropTypes.string,
};

export { SelectChainDialog };