import React, {
} from 'react';

import {
  Box,
  Button,
  Modal as MuiModal,
  useTheme,
} from '@mui/material';
import {
  Close as CloseIcon
} from '@mui/icons-material';
import { FlexCenter } from '.';

import PropTypes from 'prop-types';

const Modal = (props) => {

  const theme = useTheme();
  const { children, confirmDisabled, confirmHandler, open, closeHandler } = props;

  const buttonStyles = {
    fontSize: '12px',
    backgroundColor: theme.palette.steamx.orange,
    color: theme.palette.steamx.white,
    borderRadius: '6px',
    margin: '0 0.5em',
    padding: '6px 12px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.steamx.light,
    }
  }
  const buttonDangerStyles = Object.assign({}, buttonStyles, { backgroundColor: theme.palette.steamx.red, minWidth: '0px'});

  const modalStyles = {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    justifyContent: 'center',
  }
  const modalBoxStyles = {
    bgcolor: theme.palette.background.alt,
    borderRadius: '14px',
    border: `2px solid ${theme.palette.steamx.light}`, 
    boxShadow: `0px 0px 20px 5px ${theme.palette.steamx.light}`, 
    p: 4,
  }

  return (
    <MuiModal
      open={open}
      sx={modalStyles}
    >
      <Box sx={modalBoxStyles}>
        {children}
        <FlexCenter mt="2em">
          <Button sx={buttonDangerStyles} variant="contained" onClick={closeHandler}><CloseIcon /></Button>
          { confirmHandler && (
            <Button sx={buttonStyles} variant="contained" onClick={confirmHandler} disabled={confirmDisabled}>Confirm</Button>
          )}
        </FlexCenter>
      </Box>
    </MuiModal>
  )
}

Modal.propTypes = {
  children: PropTypes.object,
  confirmDisabled: PropTypes.bool,
  confirmHandler: PropTypes.func,
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func,
};

export { Modal };