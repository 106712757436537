import React from 'react'
import {
  TextField,
} from "@mui/material";
import PropTypes from 'prop-types';

const InputTextField = (props) => {
  const DECIMALS = props.decimals || 18;
  const NUMBER_FORMAT_REGEX = /^\d*\.?\d*$/;
  const NUMBER_REPLACE_REGEX = /[^0-9.]/g;

  const handleChange = (event) => {
    if (!NUMBER_FORMAT_REGEX.test(event.target.value)){
      event.target.value = event.target.value.replace(NUMBER_REPLACE_REGEX, '');
    }
    // replaces all occurences of '.' except the first
    event.target.value = event.target.value.replace(/(?<=\..*)\./g, '');
    
    // don't allow more than 6 decimal places
    const index = event.target.value.indexOf('.');
    if (index > 0){
      event.target.value = event.target.value.substring(0, index + DECIMALS + 1);
    }
    if (props.onChange){ props.onChange(event); }
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && props.onEnter){
      props.onEnter(event);
    }
  }
  
  return (
    <TextField 
      hiddenLabel 
      variant="standard" 
      InputProps={{disableUnderline: true, }}
      sx={{
        // no longer necessary as the text field is not type="number"
        // '& input[type=number]::-webkit-outer-spin-button': {
        //   '-webkit-appearance': 'none',
        //   margin: 0
        // },
        // '& input[type=number]::-webkit-inner-spin-button': {
        //     '-webkit-appearance': 'none',
        //     margin: 0
        // },
        '& MuiInput-root::before': {
          borderBottom: 'none',
        }
      }}
      disabled={props.disabled}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={props.placeholder}
      value={props.value}
    />
  );
}
InputTextField.propTypes = {
  disabled: PropTypes.bool,
  decimals: PropTypes.number,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export { InputTextField };