import PropTypes from 'prop-types';
import React, { 
  useEffect, 
  useState, 
} from 'react';
import { 
  useLocation, 
  useNavigate 
} from 'react-router-dom';

import { 
  Box,
  Collapse,
  List, 
  ListItem,
  ListItemButton, 
  ListItemIcon, 
  ListItemText, 
} from "@mui/material";
import { 
  AddShoppingCartOutlined,
  AnalyticsOutlined,
  AtmOutlined,
  CompareArrowsOutlined,
  CurrencyExchangeOutlined,
  ExpandLess,
  ExpandMore,
  ForumOutlined,
  HelpOutlineOutlined,
  HomeOutlined,
  LoyaltyOutlined,
  LockOpenOutlined,
  PersonAddAlt1Outlined,
  OpenInNewOutlined,
  ShoppingCartCheckoutOutlined,
} from "@mui/icons-material";

const items = [
  {
    text: "Home",
    icon: <HomeOutlined />,
    link: "/",
  },
  {
    category: "Developers",
    items: [{
        text: "Apply",
        icon: <PersonAddAlt1Outlined />,
        link: "/apply",
      },
      {
        text: "Testnet Explorer",
        icon: <AnalyticsOutlined />,
        link: "https://build.steamexchange.io/",
      },
      // {
      //   text: "Testnet Explorer",
      //   icon: <AnalyticsOutlined />,
      //   link: "/testnet-explorer",
      // },
      {
        text: "Faucet",
        icon: <AtmOutlined />,
        link: "/faucet",
      },
      {
        text: "Presale",
        icon: <AddShoppingCartOutlined />,
        link: "/presale",
      },
      {
        text: "Locker",
        icon: <LockOpenOutlined />,
        link: "/locker",
      },
  ]},
  {
    category: "SteamX Holders",
    items: [{
      text: "Swap",
      icon: <CurrencyExchangeOutlined />,
      link: "https://swap.steamexchange.io/",
    },
    {
      text: "Bridge",
      icon: <CompareArrowsOutlined />,
      link: "/bridge",
    },
    {
      text: "Migrate",
      icon: <ShoppingCartCheckoutOutlined />,
      link: "/migrate",
    },
    {
      text: "Rebate",
      icon: <LoyaltyOutlined />,
      link: "/rebate",
    },
    {
      text: "Mainnet Explorer",
      icon: <AnalyticsOutlined />,
      link: "https://explore.steamexchange.io/",
    },
  ]},
  {
    category: "Support",
    items: [{
      text: "FAQ",
      icon: <HelpOutlineOutlined />,
      link: "/faq",
    },
    {
      text: "Forum",
      icon: <ForumOutlined />,
      link: "https://forum.steamexchange.io/",
    },
  ]},
];

const SidebarList = ({ isSidebarOpen }) => {

  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const [openCategory, setOpenCategory] = useState("");
  const navigate = useNavigate();

  const clickHandler = (link) => {
    if (link.startsWith('https://')){
      const tab = window.open(link, '_blank', 'noopener,noreferrer')
      if (tab){ tab.opener = null; }
    } else {
      navigate(link);
    }
  }

  const toggleCategory = (category) => {
    if (category === openCategory){ category = ''; }
    setOpenCategory(category);
  }

  const handleChevronClick = (e, category) => {
    e.stopPropagation();
    toggleCategory(category);
  }

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const listItemStyle = {
    minWidth: isSidebarOpen ? '32px' : '24px',
    margin: '4px 0',
  }
  const listItemButtonStyle = listItemStyle;

  return (
    <List>
    {items.map((item) => {
      if (item.category){
        const isCategoryOpen = item.category === openCategory;
        return (
          <Box key={item.category}>
            <ListItemButton 
              onClick={() => toggleCategory(item.category)} 
              sx={listItemButtonStyle}
            >
              <ListItemIcon>
                {isCategoryOpen ? (
                  <ExpandLess onClick={(e) => handleChevronClick(e, item.category)} />
                ) : (
                  <ExpandMore onClick={(e) => handleChevronClick(e, item.category)} />
                )}
              </ListItemIcon>
              <ListItemText primary={item.category} />
            </ListItemButton>
            <Collapse in={isCategoryOpen}>
              {item.items.map(({text, icon, link}) => (
                <ListItem
                  key={text}
                  disablePadding
                >
                  <ListItemButton
                    selected={pathname === link}
                    onClick={() => {
                      clickHandler(`${link}`);
                      setActive(link);
                    }}
                    sx={{paddingLeft: '36px', }}
                  >
                    <ListItemIcon sx={{minWidth: '40px'}}>{icon}</ListItemIcon>
                    {isSidebarOpen && (
                      <ListItemText primary={text} />
                    )}
                    { isSidebarOpen && link.startsWith('https://') && (
                      <OpenInNewOutlined />
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>
          </Box>
        );
      }
      else if (item.icon){
        const lcText = item.text.toLowerCase();
        return (
          <ListItem
            key={item.text}
            disablePadding
          >
            <ListItemButton
              selected={lcText === active}
              onClick={() => {
                navigate(`${item.link}`)
                setActive(lcText)
              }}
              sx={listItemStyle}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {isSidebarOpen && (
                <ListItemText primary={item.text} />
              )}
            </ListItemButton>
          </ListItem>
        );
      }
      return null;
    })}
  </List>    
    
  );
}

SidebarList.propTypes = {
  isSidebarOpen: PropTypes.bool,
}
export default SidebarList;