import React from 'react';

import { ComingSoon } from 'components/ComingSoon';

const Faucet = () => {
  return (
    <ComingSoon />
  );
};

export default Faucet;
