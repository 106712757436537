import React from 'react'
import { 
  useNavigate 
} from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { PoweredByRailsNetwork } from './icons/PoweredByRailsNetwork';
import PropTypes from 'prop-types';

const ComingSoon = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { text, title } = props;
  const textValue = text ? text : 'This feature is under development and will be available soon.';
  const titleValue = title ? title : 'Coming Soon'

  const containerStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage: 'linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url("/images/background-coming-soon.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const contentStyle = {
    position: 'relative',
    textAlign: 'center',
    padding: '2em',
    borderRadius: '8px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)', // Heavy box shadow
    maxWidth: '500px',
    width: '75%',
    margin: '0 auto',
    backgroundColor: theme.palette.steamx.light,
    opacity: '0.9', // Adjust the opacity as needed (1 is fully opaque)
  };

  const titleStyle = {
    fontWeight: 'bold',
    margin: '0.5em 0',
    color: theme.palette.steamx.orange,
  };

  const textStyle = {
    fontSize: '1.0em',
    marginBottom: '0.25em',
    color: theme.palette.steamx.white,
  };

  const buttonStyle = {
    fontSize: '14px',
    backgroundColor: theme.palette.steamx.orange,
    color: theme.palette.steamx.white,
    borderRadius: '6px',
    fontWeight: 'bold',
    padding: '8px 16px',
    margin: '1.5em 0',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.background.alt,
    }
  }

  return (
    <Box sx={containerStyle}>
      <Box sx={contentStyle}>
        <Box sx={{
          maxWidth: '275px', margin: '1em auto',
        }}>
          <PoweredByRailsNetwork />
        </Box>
        <Typography id="coming-soon-modal-title" variant="h1" sx={titleStyle}>
          {titleValue}
        </Typography>
        <Typography id="coming-soon-modal-description" variant="h3" sx={textStyle}>
          {textValue}
        </Typography>
        <Button onClick={() => navigate("/")} sx={buttonStyle}>Go back to homepage</Button>
        { props.children }
      </Box>
    </Box>
  )
}

ComingSoon.PropTypes = {}

ComingSoon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  text: PropTypes.string,
  title: PropTypes.string,
};

export { ComingSoon };