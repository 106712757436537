import React from 'react'

const SteamX = () => {
  return (
    <svg 
      style={{ maxHeight: '100%', maxWidth: '100%' }}
      xmlns="http://www.w3.org/2000/svg" 
      width="2000"
      height="2000"
      viewBox="0 0 223.1 222.46"
    >
      <defs>
        <style>
          {".cls-1{fill:#d18a29;}.cls-1,.cls-2{fill-rule:evenodd;}.cls-2{fill:#fff;}"}
        </style>
      </defs>
      <path className="cls-1" d="M1070.25,429.75c.16,49.31,8.41,174.25-11.25,200.25-6.4,8.47-18.59,15.34-27.75,18.75-13.52,5-38.17,1.5-56.25,1.5H849.75L849,478.5c4-13.05,7.22-25.34,15.75-33.75,8-7.87,21.19-13.51,34.5-15.75Z" transform="translate(-849 -429)"/>
      <path className="cls-2" d="M981.75,537.25l40.5-58.5-1.21-2.26-38.54.76-22.8,33.83-2-.08-19.18-33.93-40.21-1.48-2.11,3.16,40.5,60L894,602.5c6.88.88,41.28.72,40.5-.75,5.51-3,20-27.64,23.63-35.86l1.87-.14,24,37.5,41.25-.75Z" transform="translate(-849 -429)"/>
    </svg>
  );
}

export { SteamX };