// 🚄
import React from 'react';
import { 
  Box,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { tmSymbol } from 'components/symbols';

const Home = () => {
  const theme = useTheme();

  const containerStyle = {
    backgroundImage: 'linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url("/images/background-home.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    color: theme.palette.steamx.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  };

  const contentStyle = {
    maxWidth: '600px',
    padding: '20px',
  };

  const h1Style = {
    fontSize: '2.5rem',
    margin: '1em 0',
  };

  const h2Style = {
    fontSize: '1rem',
    margin: '2em 0',
  };

  const buttonStyle = {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: theme.palette.steamx.light, 
    color: 'white',
    textDecoration: 'none',
    margin: '10px',
    borderRadius: '5px',
    fontSize: '1rem',
    transition: 'background-color 0.3s ease',
    ':hover': {
      backgroundColor: theme.palette.background.alt,
    }
  };

  return (
    <Box sx={containerStyle}>
      <Box sx={contentStyle}>
        <Typography variant="h2" sx={h1Style}>Rails Network{tmSymbol} Depot</Typography>
        <Typography variant="h3" sx={h2Style}>Welcome to your new home. Your one-stop shop for the Rails Network{tmSymbol} Ecosystem</Typography>
        <Box>
          <Link
            href="https://docs.steamexchange.io/steam-exchange-whitepaper/"
            target="_blank"
            rel="noreferrer"
            sx={buttonStyle}
          >
            White Paper
          </Link>
          <Link
            href="https://steam-exchange.gitbook.io/rails-network/"
            target="_blank"
            rel="noreferrer"
            sx={buttonStyle}
          >
            Rails Network{tmSymbol}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;