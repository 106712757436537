import React from 'react'

import BridgeComponent from 'components/Bridge'

const Bridge = () => {
  return (
    <BridgeComponent />
  )
}

export default Bridge