import React from 'react'
import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SensorsOutlined,
} from "@mui/icons-material";
import {
  USDC,
} from './../icons';
import { InputBox, InputTextField
 } from './index';
import { isMobileMediaQuery, shortenAddress } from 'helpers';
import PropTypes from 'prop-types';

import { BackgroundBox } from 'components/common';

const BridgeBox = (props) => {

  const { chainEnv, decimals, hideAmount, onAmountChange, onSelectChain, usdcBalance } = props;

  const theme = useTheme();

  const cursor = onSelectChain ? 'pointer' : 'default';
  const disabled = onSelectChain ? false : true;
  const buttonSx = {
    flexDirection: "column",
    width: "100%",
    height: "100%",
    cursor: {cursor},
  }

  const isMobile = isMobileMediaQuery();
  const primaryStackDirection = isMobile ? 'column' : 'row';
  const chainBackgroundBoxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.alt,
    width: 160,
    height: 160,
    minWidth: 160,
  };
  const chainBackgroundBoxStyleMobileOverride = {
    width: "100%",
    height: 40,
    minWidth: 320,
  }
  if (isMobile){
    Object.assign(chainBackgroundBoxStyle, chainBackgroundBoxStyleMobileOverride);
  }
  const chainNameMargin = isMobile ? "0" : "0.5em";

  return (
    <BackgroundBox padding="1.5em" width="100%" sx={{
      backgroundColor: theme.palette.steamx.light,
    }}>
      <Stack direction={primaryStackDirection} gap="0.5em">
        {/* chain box */}
        <BackgroundBox sx={chainBackgroundBoxStyle} onClick={onSelectChain}>
          { chainEnv == null && !disabled && (
            <Button sx={buttonSx} disabled={disabled}>
              { !isMobile && ( <SensorsOutlined sx={{width: 80, height: 80}} /> ) }
              <Typography variant="h5">Select Network</Typography>
            </Button> 
          )}
          { chainEnv && (
            <Button sx={buttonSx} disabled={disabled}>
              { !isMobile && ( <Box width="80px" height="80px">{chainEnv.icon}</Box> ) }
              <Typography variant="h5" color={theme.palette.neutral.medium} mt={chainNameMargin}>{chainEnv.name}</Typography>
            </Button> 
          )}
        </BackgroundBox>

        <Stack direction="column" gap="0.5em" width="100%">
          {/* asset box */}
          <BackgroundBox width="100%" height="50%" sx={{
            backgroundColor: theme.palette.background.alt,
          }}>
            <InputBox label="Asset">
              <Stack direction="row" gap="0.5em" width="100%" p="4px 0 5px">
                <Box width="24px" height="24px"><USDC /></Box>
                <Typography variant="h5">USDC</Typography>
                <Typography variant="h6" color={theme.palette.neutral.medium}>
                  {chainEnv && `${shortenAddress(chainEnv.usdc.address)}`}
                </Typography>
              </Stack>
            </InputBox>
          </BackgroundBox>
          <Stack direction="row" gap="0.5em" width="100%" height="50%">
            {/* amount box */}
            { !hideAmount && (
            <BackgroundBox width="50%" height="100%" sx={{
              backgroundColor: theme.palette.background.alt,
            }}>
              <InputBox label="Amount">
                <InputTextField placeholder="0.00" onChange={onAmountChange} decimals={decimals} />
              </InputBox>
            </BackgroundBox>
            )}
            {/* balance box */}
            <Box width="50%" height="100%" backgroundColor="transparent">
              <InputBox label="Balance">
                <InputTextField placeholder="-" disabled={true} value={usdcBalance} />
              </InputBox>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </BackgroundBox>
  )
}

BridgeBox.propTypes = {
  chainEnv: PropTypes.any,
  decimals: PropTypes.number,
  hideAmount: PropTypes.bool,
  onAmountChange: PropTypes.func,
  onSelectChain: PropTypes.func,
  usdcBalance: PropTypes.string,
};

export { BridgeBox };