import React from 'react';

import { ComingSoon } from 'components/ComingSoon';

const TestnetExplorer = () => {
  return (
    <ComingSoon />
  );
};

export default TestnetExplorer;
