import React, { useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import { useSelector } from "react-redux";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "pages/layout/Layout";

import Home from "./pages/home";
import Apply from "./pages/developers/apply";
import Faucet from "./pages/developers/faucet";
import TestnetExplorer from "./pages/developers/testnet-explorer";
import Presale from "./pages/developers/presale";
import Locker from "./pages/developers/locker";

import XSwap from "./pages/hodlers/xswap";
import Bridge from "./pages/hodlers/bridge";
import Migrate from "./pages/hodlers/migrate";
import Rebate from "./pages/hodlers/rebate";
import MainnetExplorer from "./pages/hodlers/mainnet-explorer";

import Faq from "./pages/support/faq";
import Forum from "./pages/support/forum";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <main className="app">
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              {/* Developers */}
              <Route path="/apply" element={<Apply />} />
              <Route path="/faucet" element={<Faucet />} />
              <Route path="/testnet-explorer" element={<TestnetExplorer />} />
              <Route path="/presale" element={<Presale />} />
              <Route path="/locker" element={<Locker />} />
              {/* Users */}
              <Route path="/xswap" element={<XSwap />} />
              <Route path="/bridge" element={<Bridge />} />
              <Route path="/migrate" element={<Migrate />} />
              <Route path="/rebate" element={<Rebate />} />
              <Route path="/mainnet-explorer" element={<MainnetExplorer />} />
              {/* Support */}
              <Route path="/faq" element={<Faq />} />
              <Route path="/forum" element={<Forum />} />
            </Route>
          </Routes>
        </main>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
