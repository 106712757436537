import React, {
  useState,
} from 'react';

import {
  Box,
  Button,
  Typography,
  useTheme,
} from "@mui/material";

import { ComingSoon } from 'components/ComingSoon';

const Rebate = () => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const buttonStyle = {
    color: theme.palette.steamx.white,
    textDecoration: 'underline',
  }
  const modalStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '999',
  };
  const modalContentStyle = {
    backgroundColor: theme.palette.steamx.light,
    border: '2px solid ' + theme.palette.steamx.orange, // Your desired modal border style
    borderRadius: '8px',
    padding: '20px',
    maxWidth: '85%',
    maxHeight: '85%',
    overflowY: 'auto',
    color: theme.palette.steamx.white,
    position: 'static', // Set the box as static
  };
  const modalButtonStyle = {
    fontSize: '14px',
    backgroundColor: theme.palette.steamx.orange,
    color: theme.palette.steamx.white,
    borderRadius: '6px',
    fontWeight: 'bold',
    padding: '8px 16px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.background.alt,
    }
  }

  const showTnC = () => {
    setIsModalOpen(true);
  }
  const hideTnC = () => {
    setIsModalOpen(false);
  }
  return (
    <>
      <ComingSoon 
        text="Rebate protocols have not yet been initialized."
      >
        <Box>
          <Button onClick={showTnC} sx={buttonStyle}>View Terms and Condititions</Button>
        </Box>
      </ComingSoon>
      { isModalOpen && 
        <Box sx={modalStyle}>
          <Box sx={modalContentStyle}>
            <Typography variant="h3">Terms and Conditions</Typography>
              <ol>
                <li>
                  This rebate policy is applicable only to investors who participate 
                  in the migration from BSC to Rails Network.
                </li>
                <li>
                  The rebates will apply in the following tier structure:
                  <ol type="i">
                    <li>Tier 1: Full Rebate (Available for 30 Days)
                      <ul>
                        <li>Eligibility: Investors who have never sold a single 
                          coin on Rails Network after the migration.</li>
                        <li>Rebate Amount: You will receive a 100% rebate of 
                          the fees you paid during your initial purchase on BSC, 
                          up to a maximum of 45,000 Coins.</li>
                        <li>Expiration: Tier 1 will expire after 30 days. After 
                          30 days, eligible investors can still claim Tier 2.</li>
                      </ul>
                    </li>
                    <li>Tier 2: Partial Rebate (Available for 45 Days)
                      <ul>
                        <li>Eligibility: Investors who have sold even a single 
                          one of their coins on Rails Network after the 
                          migration.</li>
                        <li>Rebate Amount: You will receive a 25% rebate of 
                          the fees you paid during your initial purchase on BSC, 
                          up to a maximum of 27,000 Coins.</li>
                        <li>Expiration: Tier 2 will be available for 45 days. 
                          After 45 days, rebates will be disabled.</li>
                      </ul>
                    </li>
                    
                  </ol>
                </li>
                <li>
                  If you have already reached the maximum threshold for a rebate, you can still participate using 
                  a new wallet. Simply create a new wallet and buy in by paying the 13% fee on the new wallet. 
                  The tier system above will apply.
                </li>
                <li>
                  If you attempt to mitigate these terms and conditions by transferring tokens from your wallet to 
                  a new wallet to take advantage of this rebate policy, your new wallet will be disqualified 
                  from participating, and you will not be eligible for any rebate.
                </li>
                <li>
                  This is not financial advice, and you should always consult with a financial advisor before 
                  making any investment decisions.
                </li>
                <li>
                  Steam Exchange reserves the right to modify or cancel the rebate policy at any time, 
                  without prior notice.
                </li>
                <li>
                  By participating in this rebate policy, you agree to these terms and conditions.
                </li>
              </ol>
              <Box sx={{ textAlign: 'center' }}>
                <Button onClick={hideTnC} sx={modalButtonStyle}>Close</Button>
              </Box>
          </Box>
        </Box> 
      }
    </>
  );
};

export default Rebate;
