import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { 
  Box,
  Drawer,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  ArrowForwardIos
} from "@mui/icons-material";
import { isMobileMediaQuery } from 'helpers';

import ConnectButton from './ConnectButton';
import SidebarHeader from './sidebar/SidebarHeader';
import SidebarList from './sidebar/SidebarList';
import SidebarFooter from './sidebar/SidebarFooter';

const Sidebar = ({ drawerWidth }) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(true);
  const [isPeeking, setIsPeeking] = useState(false);

  const isMobile = isMobileMediaQuery();
  const peekingSize = drawerWidth / 4;
  const peekWidth = 20; // Width of the peeking sidebar
  const sidebarRef = useRef(null);

  const sidebarStyle = {
    transform: `translateX(${isOpen ? 0 : isPeeking || isMobile ? -drawerWidth + peekWidth : -drawerWidth}px)`,
    transition: 'transform 0.4s ease',
    overflowX: 'hidden',
    width: drawerWidth,
    background: `radial-gradient(circle, ${theme.palette.steamx.dark} 1%, #000 99%)`,
  };

  const sidebarContentStyle = {
    maxHeight: '100%',
    overflowY: 'auto',
  }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setIsPeeking(false);
  }

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isMobile && !isOpen && e.clientX < peekingSize){
        setIsPeeking(true);
      } else if (!isOpen){
        setIsPeeking(false);
      }
    };
    const handleOutsideClick = (e) => {
      if (isOpen && sidebarRef.current && !sidebarRef.current.contains(e.target)){
        setIsOpen(false);
        setIsPeeking(false);
      }
    };

    if (!isMobile){
      window.addEventListener('mousemove', handleMouseMove);
    }
    document.addEventListener('click', handleOutsideClick);
  
    return () => {
      if (!isMobile){
        window.removeEventListener('mousemove', handleMouseMove);
      }
      document.removeEventListener('click', handleOutsideClick);
    }
  }, [isOpen, isMobile]);
  
  return (
    <Box ref={sidebarRef} position="relative">
      <Drawer
        variant="permanent"
        open={isOpen || isPeeking || isMobile}
        sx={{
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          zIndex: 2000,
        }}
        PaperProps={{
          sx: {
            ...sidebarStyle,
          },
        }}
      >
        <Box width="100%" style={sidebarContentStyle}>
          {/* Header  */}
          <SidebarHeader
            isSidebarOpen={isOpen}
          ></SidebarHeader>
          {/* Menu */}
          <SidebarList 
            isSidebarOpen={isOpen}
          />
          {/* Connect */}
          <ConnectButton isSidebarOpen={isOpen} />
          {/* Footer */}
          <SidebarFooter 
            isSidebarOpen={isOpen}
          />
        </Box>
        {isPeeking && !isOpen && (
          <Box onClick={toggleSidebar} sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 5
          }}/>
        )}
        <IconButton onClick={toggleSidebar} sx={{
          position: 'absolute',
          top: '50%',
          left: `calc(${drawerWidth}px - 8px)`, // Move the button 40px inside from the sidebar's right end
          transform: 'translateY(-50%)',
          backgroundColor: theme.palette.steamx.orange,
          borderRadius: '15%',
          width: '45px',
          height: '75px',
          zIndex: 1300, // Set a significantly higher z-index
          transition: 'opacity 0.3s ease, transform 0.4s ease',
          opacity: isPeeking || !isOpen ? 1 : 0.7,
          '&:hover': {
            backgroundColor: theme.palette.steamx.light,
          }
        }}>
          <ArrowForwardIos color="action" />
        </IconButton>
      </Drawer>
    </Box>
  )
}

Sidebar.propTypes = {
  drawerWidth: PropTypes.number,
};
export default Sidebar;