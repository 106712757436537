import React from 'react'

const Rails = () => {
  return (
    <svg
      style={{ maxHeight: '100%', maxWidth: '100%' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 447.35 514.43"
    >
      <polygon fill="#a1acb7" points="447.35 0 447.35 514.43 95.32 263.89 95.32 193.05 388.52 411.44 388.52 115.55 321.79 165.96 227.29 103.66 314.73 94.62 447.35 0"/>
      <polygon fill="#a1acb7" points="352.03 250.71 352.03 321.38 58.84 102.99 58.84 399.04 122.6 347.63 233.54 419.47 145.94 410.43 0 514.43 0 0 352.03 250.71"/>
    </svg>
  );
}

export { Rails };