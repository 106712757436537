const DEPOT_HEALTHCHECK_RAILS = "https://healthcheck.steamexchange.io";
const DEPOT_HEALTHCHECK_RAILS_TESTNET = "https://healthcheck.testnet.steamexchange.io";

const NETWORK_ETHEREUM = {
  id: "1",
  name: "Ethereum",
  short: "eth",
  usdc: {
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    name: "USD Coin",
    version: "2",
  },
  bridge: {
    address: "0x2Add4242B7eb8Aa167ee374f706B8DB28258C708",
  },
  rpc: "https://eth-mainnet.public.blastapi.io",
  token: {
    symbol: "ETH",
  },
  healthcheck: DEPOT_HEALTHCHECK_RAILS,
  explorer: "https://etherscan.io",
};

const NETWORK_SEPOLIA = {
  id: "11155111",
  name: "Sepolia",
  short: "eth",
  usdc: {
    address: "0x678ab796a141201e5291C1953783A07E3d17F6A9",
    name: "USD Coin",
    version: "2",
  },
  bridge: {
    address: "0x6157a671249616A7E9C90F5d342f730Aac08Bd86",
  },
  rpc: "https://eth-sepolia.public.blastapi.io",
  token: {
    symbol: "ETH",
  },
  healthcheck: DEPOT_HEALTHCHECK_RAILS_TESTNET,
  explorer: "https://sepolia.etherscan.io",
};

const NETWORK_RAILS = {
  id: "6278",
  name: "Rails",
  short: "rails",
  usdc: {
    address: "0x0000000000000000000000000000000000627801",
    name: "USD Coin",
    version: "2",
  },
  bridge: {
    address: "0x0000000000000000000000000000000000627803",
    bonus: "0x00fDD1afbF69f2dFC8d668B4A4f64d8aE902C8b6",
  },
  rpc: "https://mainnet.steamexchange.io",
  token: {
    symbol: "STEAMX",
  },
  healthcheck: DEPOT_HEALTHCHECK_RAILS,
  explorer: "https://explore.steamexchange.io",
};

const NETWORK_RAILS_TESTNET = {
  id: "24116",
  name: "Rails Testnet", // "where it [the earth] is red"
  // name: "Aupaluk", // "where it [the earth] is red"
  short: "rails",
  usdc: {
    address: "0x0000000000000000000000000000000000627801",
    name: "USD Coin",
    version: "2",
  },
  bridge: {
    address: "0x0000000000000000000000000000000000627803",
    bonus: "0xE016A296f4F1A87f5815A335671fbB5aE2BBe387",
  },
  rpc: "https://testnet.steamexchange.io",
  token: {
    symbol: "tSTEAMX",
  },
  healthcheck: DEPOT_HEALTHCHECK_RAILS_TESTNET,
  explorer: "https://build.steamexchange.io",
};

const NETWORK_BSC = {
  id: "56", // 0x38
  name: "BSC",
  short: "bsc",
  steamx: {
    address: "0xc0924edefb2c0c303de2d0c21bff07ab763163b5",
    name: "SteamX",
    version: "1",
  },
  bridge: {
    address: "",
  },
  migration: {
    address: "0xdB6D6C6188AAD23C8443C280BbB53993C4434622",
  },
  // rpc: "https://bsc-mainnet.public.blastapi.io",
  rpc: "https://bsc-dataseed.binance.org",
  token: {
    symbol: "BNB",
  },
  healthcheck: DEPOT_HEALTHCHECK_RAILS,
  explorer: "https://bscscan.com",
};

const NETWORK_BSC_TESTNET = {
  id: "97", // 0x61
  name: "BSC Testnet",
  short: "bsc",
  steamx: {
    address: "0x5FeA2De8FDc23B196d6A0584593813a64743C947",
    name: "SteamX",
    version: "1",
  },
  bridge: {
    address: "",
  },
  migration: {
    address: "0x3ff533b614e35Dbde64cC78ac7663323825F9A24",
  },
  // rpc: "https://bsc-testnet.public.blastapi.io",
  rpc: "https://data-seed-prebsc-1-s1.binance.org:8545",
  token: {
    symbol: "tBNB",
  },
  healthcheck: DEPOT_HEALTHCHECK_RAILS_TESTNET,
  explorer: "https://testnet.bscscan.com",
};

const NETWORK_POLYGON = {
  id: "137", // 0x89
  name: "Polygon",
  short: "polygon",
  usdc: {
    address: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
    name: "USD Coin",
    version: "2",
  },
  bridge: {
    address: "0xbA05AFC65BEF2E2bdfF7E248C7908e4b72e5545C",
  },
  rpc: "https://polygon-mainnet.public.blastapi.io",
  token: {
    symbol: "MATIC",
  },
  healthcheck: DEPOT_HEALTHCHECK_RAILS,
  explorer: "https://polygonscan.com/",
};

const NETWORK_POLYGON_MUMBAI = {
  id: "80001", // 0x13881
  name: "Mumbai",
  short: "polygon",
  usdc: {
    // address: "0x9999f7fea5938fd3b1e26a12c3f2fb024e194f97",
    address: "0x431279F20262a1d4d79A1eD03b0573dEEc43806A",
    name: "USD Coin",
    version: "2",
  },
  bridge: {
    address: "0x74457F48e101DF03c31D5A0f2A6C72ae98526dC9",
  },
  rpc: "https://polygon-testnet.public.blastapi.io",
  token: {
    symbol: "tMATIC",
  },
  healthcheck: DEPOT_HEALTHCHECK_RAILS_TESTNET,
  explorer: "https://mumbai.polygonscan.com/",
};

const HEALTH_CHECK_TOLERANCE = 5;

module.exports = {
  NETWORK_ETHEREUM,
  NETWORK_SEPOLIA,
  NETWORK_RAILS,
  NETWORK_RAILS_TESTNET,
  NETWORK_BSC,
  NETWORK_BSC_TESTNET,
  NETWORK_POLYGON,
  NETWORK_POLYGON_MUMBAI,
  HEALTH_CHECK_TOLERANCE,
};
