import React, { 
  useEffect,
  useRef,
  useState,
} from "react"
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Table, TableHead, TableBody, TableRow, TableCell,
  useTheme,
} from "@mui/material";
import {
  ArrowDownwardOutlined,
  Close as CloseIcon,
  KeyboardDoubleArrowDownOutlined,
} from "@mui/icons-material";

import { CollapsibleAlert } from "./CollapsibleAlert";
import { 
  FlexBetween,
  Modal,
} from "./common";
import { 
  BridgeBox,
  BusyDialog,
  SelectChainDialog,
} from "./bridge/index";
import { ethers } from "ethers";
import { formatBalance, isMobileMediaQuery, shortenNonce } from "helpers";

import {
  NETWORK_ETHEREUM,
  NETWORK_SEPOLIA,
  NETWORK_RAILS,
  NETWORK_RAILS_TESTNET,
  NETWORK_POLYGON,
  NETWORK_POLYGON_MUMBAI,
  HEALTH_CHECK_TOLERANCE,
} from "config";
import {
  Ethereum as EthereumIcon,
  Polygon as PolygonIcon,
  Rails as RailsIcon,
} from './icons';

NETWORK_ETHEREUM.icon = <EthereumIcon />;
NETWORK_SEPOLIA.icon = <EthereumIcon />;
NETWORK_RAILS.icon = <RailsIcon />;
NETWORK_RAILS_TESTNET.icon = <RailsIcon />;
NETWORK_POLYGON.icon = <PolygonIcon />;
NETWORK_POLYGON_MUMBAI.icon = <PolygonIcon />;

const PAIRS = [
  {
    from: NETWORK_POLYGON,
    to: NETWORK_RAILS,
    type: "Mainnet",
  },
  {
    from: NETWORK_ETHEREUM,
    to: NETWORK_RAILS,
    type: "Mainnet",
  },
  {
    from: NETWORK_RAILS,
    to: NETWORK_ETHEREUM,
    type: "Mainnet",
  },
  {
    from: NETWORK_SEPOLIA,
    to: NETWORK_RAILS_TESTNET,
    type: "Testnet",
  },
  {
    from: NETWORK_RAILS_TESTNET,
    to: NETWORK_SEPOLIA,
    type: "Testnet",
  },
  {
    from: NETWORK_POLYGON_MUMBAI,
    to: NETWORK_RAILS_TESTNET,
    type: "Testnet",
  },
];

const USDC_DECIMALS = 6;
const GAS_DECIMALS = 6;

const { styled } = require("@mui/system");
const FlexCenter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%"
});

const STATUS_AUTHORIZED = 'Authorized';
const STATUS_DEPOSITED = 'Deposited';
const STATUS_CLAIMED = 'Claimed';

const LOCAL_STORAGE_KEY_BRIDGE_HISTORY = 'bridge:history';

const Bridge = () => {

  const theme = useTheme();
  const wallet = useSelector((state) => state.wallet);
  const isMobile = isMobileMediaQuery();

  const amount = useRef(0n);
  const setAmount = (value) => { amount.current = value; }
  
  const [chainEnvFrom, setChainEnvFrom] = useState(null);
  const [chainEnvTo, setChainEnvTo] = useState(null);
  const [usdcBalanceFrom, setUsdcBalanceFrom] = useState(0);
  const [usdcBalanceTo, setUsdcBalanceTo] = useState(0);
  const [chainDialogOpen, setChainDialogOpen] = useState(false);
  const [healthy, setHealthy] = useState(false);

  const [busyDialogOpen, setBusyDialogOpen] = useState(false);
  const [depositPayload, setDepositPayload] = useState(null);
  const claimPayload = useRef(null);
  const setClaimPayload = (value) => { claimPayload.current = value; }

  const [claimDisabled, setClaimDisabled] = useState(true);

  const [alertText, setAlertText] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");
  const [isAlertDisplayed, setIsAlertDisplayed] = useState(false);

  const [isBridgeModalOpen, setIsBridgeModalOpen] = useState(false);
  const [isBridgeModalReady, setIsBridgeModalReady] = useState(false);
  const [bridgeBonus, setBridgeBonus] = useState(0);
  const [bridgeFee, setBridgeFee] = useState(0);
  const [bridgeTransferAmount, setBridgeTransferAmount] = useState("");

  const storedHistory = (localStorage && localStorage.getItem) ? localStorage.getItem(LOCAL_STORAGE_KEY_BRIDGE_HISTORY) : "{}";
  const retriedBridgeHistory = storedHistory || "{}";
  const [bridgeHistory, setBridgeHistory] = useState(retriedBridgeHistory);
  const [recoverList, setRecoverList] = useState([]);

  const arrowStyles = {
    color: theme.palette.steamx.orange,
    fontSize: '2rem', 
    transition: 'transform 0.3s ease-in-out', 
      ':hover': {
      transform: 'scale(1.5)',
      filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))'
      }
  };
  const buttonStyles = {
    fontSize: '12px',
    backgroundColor: theme.palette.steamx.orange,
    color: theme.palette.steamx.white,
    borderRadius: '6px',
    margin: '0 0.5em',
    padding: '6px 12px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.steamx.light,
    }
  }
  const buttonDangerStyles = Object.assign({}, buttonStyles, { backgroundColor: theme.palette.steamx.red, minWidth: '0px'});

  const modalBoxTableRowStyles = {
    marginBottom: '6px',
    borderBottom: `2px solid ${theme.palette.steamx.light}`,
    paddingBottom: '6px'
  }
  const modalBoxBalanceStyles = {
    textAlign: 'right',
    color: theme.palette.steamx.orange,
    fontFamily: 'monospace',
  }
  const modalBoxBalanceNegativeStyles = Object.assign({}, modalBoxBalanceStyles);
  modalBoxBalanceNegativeStyles.color = theme.palette.steamx.red;

  const showSelectChainDialog = () => {
    if (wallet.account){
      setChainDialogOpen(true);
    }
    else {
      showAlert('error', 'You must connect your wallet first');
    }
  }
  const hideSelectChainDialog = async (pair) => {
    setChainDialogOpen(false);
    setBridgeBoxes(pair, wallet.account);
  }
  const checkActiveChainId = async() => {
    const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
    // if (chainEnvFrom && chainEnvFrom.id !== parseInt(window.ethereum.chainId, 16)){
    if (chainEnvFrom && chainEnvFrom.id !== parseInt(currentChainId, 16)){
      const chainId = '0x' + parseInt(chainEnvFrom.id).toString(16);
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainId }]
        });
        return true;
      }
      catch(e){
        // request rejected by client
        if (e.code === 4001){
          return false;
        }
        // Unrecognized chain ID
        if (e.code === 4902){
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
              blockExplorerUrls: [chainEnvFrom.explorer],
              iconUrls: ["https://www.gitbook.com/cdn-cgi/image/width=40,dpr=2,height=40,fit=contain,format=auto/https%3A%2F%2F3668210172-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FzfvgvlHXVAdU3Ggn1Vyt%252Ficon%252FzhSLdf6YMAVfNVplwBLZ%252Ftwitter.jpg%3Falt%3Dmedia%26token%3D05502f2c-42d5-413c-8d94-2b4c34fef788"],
              rpcUrls: [chainEnvFrom.rpc],
              chainId: '0x' + parseInt(chainEnvFrom.id).toString(16),
              chainName: chainEnvFrom.name,
              nativeCurrency: {
                name: "SteamX",
                symbol: chainEnvFrom.token.symbol,
                decimals: 18,
              },
            }]
          }).catch(async (e) => {
            console.log(e.message);
            return false;
          }).finally(() => {
            return false;
          })
        }
      }
    }
  }
  const formatUsdcBalance = (amount, decimals = 2) => {
    if (!amount){ return "0.00"; }
    if (amount === '...'){ return '...'; }
    return formatBalance(ethers.formatUnits(amount, USDC_DECIMALS), decimals);
  }
  const updateUsdcBalanceFrom = async (account, env) => {
    updateUsdcBalance(account, env, setUsdcBalanceFrom);
  }
  const updateUsdcBalanceTo = async (account, env) => {
    updateUsdcBalance(account, env, setUsdcBalanceTo);
  }
  const updateUsdcBalance = async(account, env, setter) => {
    setter("...");
    const abi = [
      'function balanceOf(address) view returns (uint256)'
    ];
    const provider = new ethers.JsonRpcProvider(env.rpc);
    const contract = new ethers.Contract(env.usdc.address, abi, provider);

    const balance = await contract.balanceOf(account);
    setter(balance.toString());
  }
  const checkHealth = async(pair) => {

    if (!fetch){
      showAlert('error', 'fetch API not supported');
    }
    if (!pair.from.healthcheck){
      showAlert('error', 'Bridge healthcheck not supported');
      setHealthy(true);
      return;
    }

    try {
      const response = await fetch(pair.from.healthcheck);
      if (response.ok){
        const data = await response.json();
        const providerFrom = new ethers.JsonRpcProvider(pair.from.rpc);
        const providerTo = new ethers.JsonRpcProvider(pair.to.rpc);
        const blockNumberDifferenceFrom = await providerFrom.getBlockNumber() - data[pair.from.short];
        const blockNumberDifferenceTo = await providerTo.getBlockNumber() - data[pair.to.short];
        if (blockNumberDifferenceFrom < HEALTH_CHECK_TOLERANCE && blockNumberDifferenceTo < HEALTH_CHECK_TOLERANCE){
          setHealthy(true);
          setIsAlertDisplayed(false);
          return;
        }
      }
    } catch (e){ console.error('Heathcheck failed'); }
    
    showAlert('error', 'The bridge is currently offline');
    setHealthy(false);
  }

  const setBridgeBoxes = async (pair, account) => {

    if (!account){
      setUsdcBalanceFrom(0);
      setUsdcBalanceTo(0);
      return;
    }
    if (!pair || !pair.from || !pair.to){ return; }
    
    if (chainEnvFrom?.id != pair.from.id && chainEnvTo?.id != pair.to.id){
      setDepositPayload(null);
    }

    setChainEnvFrom(pair.from);
    setChainEnvTo(pair.to);

    await checkHealth(pair);

    try {
      await updateUsdcBalanceFrom(account, pair.from);
      await updateUsdcBalanceTo(account, pair.to);
    } catch (error){
      console.log(error);
    }
  }

  useEffect(() => {

    // only update the boxes & accounts if the chain has not been changed via metamask
    // this decouples the "from" box from the chain/network selected in metamask 
    if (chainEnvFrom && chainEnvTo){
      const pair = {
        from: chainEnvFrom,
        to: chainEnvTo,
      }
      setBridgeBoxes(pair, wallet.account);
      setClaimPayload(null);
    }
  }, [wallet])

  useEffect(() => {
    const history = JSON.parse(bridgeHistory);
    const pairId = `${chainEnvFrom?.id}-${chainEnvTo?.id}`;
    const list = Object.values(history[wallet.account] || {})
      .filter(item => item.pairId == pairId)
      .sort((a, b) => b.timestamp - a.timestamp)
    ;
    setRecoverList(list);
    
    if (localStorage && localStorage.setItem){
      localStorage.setItem(LOCAL_STORAGE_KEY_BRIDGE_HISTORY, bridgeHistory);
    }
  }, [bridgeHistory, chainEnvFrom, chainEnvTo])

  const handleAmountChange = (event) => {
    if (!event.target.value){ return; }
    const value = ethers.parseUnits(event.target.value, USDC_DECIMALS);
    // if (depositPayload && amount.current != value){
    if (amount.current != value){
      setDepositPayload(null);
      setClaimDisabled(true);
    }
    setAmount(value);
  }
  const deleteFromHistory = (payload) => {
    const data = JSON.parse(bridgeHistory);
    if (!data || !payload || !payload.from){ return; }
    const from = payload.from;
    if (data[from][payload.nonce]){
      delete data[from][payload.nonce];
      setBridgeHistory(JSON.stringify(data));
    }
  }
  const updateBridgeHistory = (payload, receipt, status) => {
    const data = JSON.parse(bridgeHistory);
    const from = payload.from;
    if (!data[from]){ data[from] = {}; }
    data[from][payload.nonce] = {
      confirmation: receipt ? `${chainEnvTo.explorer}/tx/${receipt.hash}` : `${chainEnvTo.explorer}/address/${from}`,
      pairId: `${chainEnvFrom.id}-${chainEnvTo.id}`,
      payload: payload,
      status: status,
      timestamp: new Date().valueOf(),
    };
    setBridgeHistory(JSON.stringify(data));
  }
  const getRecoverAction = (status) => {
    switch (status) {
      case STATUS_AUTHORIZED : { return 'DEPOSIT'; }
      case STATUS_DEPOSITED : { return 'CLAIM'; }
      case STATUS_CLAIMED : { return 'CONFIRMATION'; }
    }
  }
  const recover = async(data) => {
    if (!data){
      showAlert('error', 'Invalid Recovery Data');
      return;
    }
    if (data.status == STATUS_CLAIMED){
      const url = data.confirmation;
      const tab = window.open(url, '_blank', 'noopener,noreferrer')
      if (tab){ tab.opener = null; }
      return;
    }

    if (data.status == STATUS_DEPOSITED){
      setDepositPayload(null);
      setClaimPayload(data.payload);
      setClaimDisabled(false);

      if (isChainEnvFromEthOrPolygon(chainEnvFrom)){
      // if (chainEnvFrom && ( chainEnvFrom.id == "1" || chainEnvFrom.id == "11155111" )){
        claimOnRails();
      }
      if (isChainEnvFromRails(chainEnvFrom)){
      // if (chainEnvFrom && ( chainEnvFrom.id == "6278" || chainEnvFrom.id == "24116" )){
        claimOnEth();
      }
      return;
    }

    if (data.status == STATUS_AUTHORIZED){
      setAmount(data.payload.value);
      setClaimDisabled(true);
      setDepositPayload(data.payload);
      setClaimPayload(data.payload);
      confirm();
    }
  }

  const formatEthGasPrice = (value) => {
    const s = ethers.formatEther(value);
    const decimalIndex = s.indexOf('.');
    return s.substring(0, decimalIndex + GAS_DECIMALS);
  }

  const checkSufficientBalanceForGas = async () => {
    if (isChainEnvFromRails(chainEnvFrom)){
      const FORWARD_GAS_COST = 139350n;
      const provider = await new ethers.JsonRpcProvider(chainEnvTo.rpc);
      const feeData = await provider.getFeeData();
  
      const requiredBalance = FORWARD_GAS_COST * feeData.gasPrice * 12n / 10n; // add 20% for rough estimate & gas price volatility
      const ethBalance = await provider.getBalance(wallet.account);
      const hasSufficientBalance = ethBalance > requiredBalance;

      // console.log(`${chainEnvTo.name} balance: ${ethBalance}`);
      // console.log(`Gas cost: ${requiredBalance}`);

      if (!hasSufficientBalance){
        showAlert('error', `Insufficient ${chainEnvTo.name} balance for gas (min ${formatEthGasPrice(requiredBalance)} ${chainEnvTo.token.symbol} required)`);
        return false;
      }
      return true;
    }
    // sending to rails
    return true;
  }

  const checkVaultHasSufficientFunds = async (transferAmount) => {
    if (isChainEnvFromRails(chainEnvFrom)){
      const abi = [
        'function balanceOf(address) view returns (uint256)'
      ];
      const provider = new ethers.JsonRpcProvider(chainEnvTo.rpc);
      const contract = new ethers.Contract(chainEnvTo.usdc.address, abi, provider);
      const vaultBalance = await contract.balanceOf(chainEnvTo.bridge.address);

      const hasSufficientFunds = vaultBalance >= transferAmount;
      if (!hasSufficientFunds){
        showAlert('error', `Insufficient funds! Please contact support`);
        return false;
      }
      return true;
    }
    // sending to rails
    return true;
  }

  const authorize = async () => {
    
    if (!(amount.current > 0)){
      showAlert('error', 'Invalid Amount');
      return;
    }
    if (amount.current > usdcBalanceFrom){
      showAlert('error', 'Insufficient Balance');
      return;
    }

    const chainChangeApproved = await checkActiveChainId();
    if (!chainChangeApproved){ return; }

    setDepositPayload(null);
    setClaimPayload(null);
    setClaimDisabled(true);
    const nonce = await ethers.hexlify(ethers.randomBytes(32));

    const payload = {
      from: wallet.account,
      // this value must be the bridge address as the recipient
      // of the `receiveWithAuthorization`
      to: chainEnvFrom.bridge.address,
      value: amount.current.toString(),
      validAfter: 0n.toString(),
      validBefore: ethers.MaxUint256.toString(),
      nonce: nonce,
    };

    const types = {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
      ],
      ReceiveWithAuthorization: [
        { name: "from", type: "address" },
        { name: "to", type: "address" },
        { name: "value", type: "uint256" },
        { name: "validAfter", type: "uint256" },
        { name: "validBefore", type: "uint256" },
        { name: "nonce", type: "bytes32" },
      ],
    };

    const tokenDomain = {
      name: chainEnvFrom.usdc.name,
      version: chainEnvFrom.usdc.version,
      chainId: chainEnvFrom.id, 
      verifyingContract: chainEnvFrom.usdc.address, 
    };    

    const typedData = JSON.stringify({
      domain: tokenDomain,
      message: payload,
      primaryType: 'ReceiveWithAuthorization',
      types: types,
    });
    const params = [wallet.account, typedData];
    const method = 'eth_signTypedData_v4';

    const sign = async (method, params) => {
      try {
        const signature = await window.ethereum.request({ method, params });
        return signature;
      } catch (e) {
        console.log(e);
        // e.code === 4100 => "The requested account and/or method has not been authorized by the user."
        return null;
      }
    }
    const signature = await sign(method, params);
    if (signature == null){ 
      return; 
    }

    const v = "0x" + signature.slice(130, 132);
    const r = signature.slice(0, 66);
    const s = "0x" + signature.slice(66, 130);

    // set the payload.to to the same address as the sender
    payload.to = payload.from;

    const hash = ethers.solidityPackedKeccak256(
      ["address", "address", "uint256", "uint256", "uint256", "bytes32"],
      [...Object.values(payload)],
      // [payload.from, payload.to, payload.amount, 0n.toString(), ethers.MaxUint256.toString(), nonce],
    );
    const depositSignature = await window.ethereum.request({
      method: "personal_sign",
      params: [wallet.account, hash]
    });
    const dp = Object.assign({}, payload, {v: parseInt(v, 16), r: r, s: s, signature: depositSignature});
    setDepositPayload(dp);
    setClaimPayload(dp);
    updateBridgeHistory(dp, null, STATUS_AUTHORIZED);
  }

  const calculateFee = async(amount) => {
    const abi = [
      'function calculateFee(uint256 value) public view returns (uint256)'
    ];
    const provider = new ethers.JsonRpcProvider(chainEnvTo.rpc);
    const contract = new ethers.Contract(chainEnvTo.bridge.address, abi, provider);

    return await contract.calculateFee(amount);
  }
  const calculateBonus = async(amount, recipient) => {
    if (!isChainEnvFromPolygon(chainEnvFrom)){ return 0n; }
    if (!chainEnvTo.bridge.bonus){ return 0n; }

    const abi = [
      'function calculateBonus(uint256 amount, address recipient) view returns (uint256)'
    ]
    const provider = new ethers.JsonRpcProvider(chainEnvTo.rpc);
    const contract = new ethers.Contract(chainEnvTo.bridge.bonus, abi, provider);

    return await contract.calculateBonus(amount, recipient);
  }
  const confirm = async() => {

    const hasSufficientBalance = await checkSufficientBalanceForGas();
    // console.log(hasSufficientBalance);
    if (!hasSufficientBalance){
      return;
    }

    const vaultHasSufficientFunds = await checkVaultHasSufficientFunds(amount.current);
    if (!vaultHasSufficientFunds){
      return;
    }

    setIsBridgeModalReady(false);
    setIsBridgeModalOpen(true);

    const fee = await calculateFee(amount.current);
    setBridgeFee(fee);
    const bonus = await calculateBonus(amount.current, wallet.account);
    setBridgeBonus(bonus);
    const credit = BigInt(amount.current) - fee + bonus;
    setBridgeTransferAmount(credit);

    setIsBridgeModalReady(true);
  }

  const deposit = async () => {
    const chainChangeApproved = await checkActiveChainId();
    if (!chainChangeApproved){ return; }

    if (depositPayload === null){ 
      console.error('No deposit payload set');
      return; 
    }
    const abi = [
      'function deposit(address, address, uint256, uint256, uint256, bytes32, uint8, bytes32, bytes32, bytes) external'
    ];
    const provider = await new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(chainEnvFrom.bridge.address, abi, signer);

    // console.log(depositPayload);
    const dp = Object.assign({}, depositPayload);
    try {
      setBusyDialogOpen(true);
      const tx = await contract.deposit(...Object.values(depositPayload));
      setIsBridgeModalOpen(false);
      await tx.wait();

      // update balance
      updateUsdcBalanceFrom(wallet.account, chainEnvFrom);
      // reset the payload
      setDepositPayload(null);
      setClaimDisabled(false);
      updateBridgeHistory(dp, null, STATUS_DEPOSITED);
    } catch (error){
      console.log(error);
      setClaimDisabled(true);
    } finally {
      setBusyDialogOpen(false);
    }
  }
  const claimOnRails = async () => {
    setClaimDisabled(true);
    setBusyDialogOpen(true);
    const provider = new ethers.JsonRpcProvider(chainEnvTo.rpc);

    const dp = Object.assign({}, claimPayload.current);
    provider.once('block', async () => {
      await updateUsdcBalanceTo(wallet.account, chainEnvTo);
      setBusyDialogOpen(false);
      updateBridgeHistory(dp, null, STATUS_CLAIMED);
    });
    setClaimPayload(null);
  }
  const getClaimPayload = async () => {
    if (!claimPayload || !claimPayload.current){ return null; }
    const abi = [
      'function getRecord(bytes32) external view returns (uint8, bytes32, bytes32)'
    ];
    const provider = new ethers.JsonRpcProvider(chainEnvFrom.rpc);
    const contract = new ethers.Contract(chainEnvFrom.bridge.address, abi, provider);

    const data = await contract.getRecord(claimPayload.current.nonce);
    const v = parseInt(data[0]);
    const r = data[1];
    const s = data[2];
    return Object.assign({}, claimPayload.current, {v: v, r: r, s: s});
  }
  const claimOnEth = async () => {
    
    const payload = await getClaimPayload();
    console.log(payload);
    if (!payload || payload.r == ethers.ZeroHash || payload.s == ethers.ZeroHash){
      showAlert('error', 'Transaction not mined yet. Please try a few seconds later!');
      return;
    }

    const abi = [
      'function forward(address, address, uint256, uint256, uint256, bytes32, uint8, bytes32, bytes32, bytes) external'
    ];
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x" + parseInt(chainEnvTo.id).toString(16) }]
      })
    } catch (error){
      console.log(error);
      return;
    }

    const provider = await new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    
    // it might be a better idea to hardcode this to getChainEnv("0xaa36a7") // getChainEnv("0x1")
    const contract = new ethers.Contract(chainEnvTo.bridge.address, abi, signer);

    try {
      setBusyDialogOpen(true);
      const tx = await contract.forward(...Object.values(payload));
      await tx.wait();

      // update balance
      provider.once('block', async () => {
        await updateUsdcBalanceTo(wallet.account, chainEnvTo);
        setBusyDialogOpen(false);
      });
      // reset the payload
      setClaimPayload(null);
      setClaimDisabled(true);
      updateBridgeHistory(payload, tx, STATUS_CLAIMED);
    } catch (error){
      console.log(error);
      setClaimDisabled(false);
      setBusyDialogOpen(false);
    }
  }

  const showAlert = (severity, text) => {
    setAlertSeverity(severity);
    setAlertText(text);
    setIsAlertDisplayed(true);
    setTimeout(() => {
      setIsAlertDisplayed(false);
    }, 10000);
  }

  const isChainEnvFromEthOrPolygon = (env) => {
    if (!env){ return false; }
    return env.id == "1" || env.id == "11155111" || isChainEnvFromPolygon(env)
  }
  const isChainEnvFromPolygon = (env) => {
    if (!env){ return false; }
    return env.id == "137" || env.id == "80001"
  }

  const isChainEnvFromRails = (env) => {
    if (!env){ return false; }
    return env.id == "6278" || env.id == "24116"
  }

  return (
    <Box id="container" width="100%" sx={{ minWidth: '424px', }}>
      <CollapsibleAlert 
        alertSeverity={alertSeverity} 
        alertText={alertText} 
        isAlertDisplayed={isAlertDisplayed}
        setIsAlertDisplayed={setIsAlertDisplayed}
      />
      <SelectChainDialog open={chainDialogOpen} onClose={hideSelectChainDialog} pairs={PAIRS} />
      <BusyDialog open={busyDialogOpen} />
      <FlexCenter maxWidth="800px" p="2em" m="2em auto" textAlign="left" flexDirection="column">
        <BridgeBox 
          chainEnv={chainEnvFrom}
          decimals={USDC_DECIMALS}
          onSelectChain={showSelectChainDialog}
          onAmountChange={handleAmountChange}
          usdcBalance={formatUsdcBalance(usdcBalanceFrom)}
        />
        <FlexBetween m="1em 0">
          <ArrowDownwardOutlined sx={arrowStyles}/>
        </FlexBetween>
        <FlexBetween>
          <Button sx={buttonStyles} variant="contained" onClick={authorize} disabled={!healthy || amount.current === 0 || wallet.account === null || depositPayload !== null || !claimDisabled}>Authorize & Sign</Button>
          <Button sx={buttonStyles} variant="contained" onClick={confirm} disabled={!healthy || depositPayload === null || !claimDisabled}>Deposit</Button>
          { isChainEnvFromEthOrPolygon(chainEnvFrom) && (
            <Button sx={buttonStyles} variant="contained" onClick={claimOnRails} disabled={!healthy || claimDisabled}>Claim</Button>
          )}
          { isChainEnvFromRails(chainEnvFrom) && (
            <Button sx={buttonStyles} variant="contained" onClick={claimOnEth} disabled={!healthy || claimDisabled}>Claim</Button>
          )}
        </FlexBetween>
        <FlexBetween m="1em 0">
          <KeyboardDoubleArrowDownOutlined sx={arrowStyles} />
        </FlexBetween>
        <BridgeBox 
          chainEnv={chainEnvTo}
          hideAmount={true}
          usdcBalance={formatUsdcBalance(usdcBalanceTo)}
        />

        <FlexBetween m="2em 0">
          <Table>
            { recoverList.length > 0 && (
              <TableHead>
                <TableRow>
                  { !isMobile && ( <TableCell align="center">Nonce</TableCell> ) }
                  <TableCell align="center">USDC</TableCell>
                  <TableCell align="center">Action</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              { recoverList.map((item) => { return (
                <TableRow key={item.payload.nonce}>
                  { !isMobile && ( <TableCell>{shortenNonce(item.payload.nonce)}</TableCell> ) }
                  <TableCell align="right">{formatUsdcBalance(item.payload.value)}</TableCell>
                  <TableCell align="center">
                    <Button sx={buttonStyles} variant="contained" onClick={() => recover(item)} disabled={!healthy}>{ getRecoverAction(item.status) }</Button>
                  </TableCell>
                  <TableCell align="center">{ item.status }</TableCell>
                  <TableCell>
                    <Button sx={buttonDangerStyles} variant="contained" onClick={() => { deleteFromHistory(item.payload) }}><CloseIcon /></Button>
                  </TableCell>
                </TableRow>
              );})}
            </TableBody>
          </Table>
        </FlexBetween>
      </FlexCenter>

      <Modal
        confirmDisabled={!isBridgeModalReady || bridgeTransferAmount <= 0}
        confirmHandler={deposit}
        open={isBridgeModalOpen}
        closeHandler={() => {
          setIsBridgeModalOpen(false)
        }}
      >
        <Table>
          <TableBody>
            <TableRow sx={modalBoxTableRowStyles}>
              <TableCell>Amount</TableCell>
              <TableCell sx={modalBoxBalanceStyles}>{formatUsdcBalance(amount.current, USDC_DECIMALS)}</TableCell>
              {/* <TableCell sx={modalBoxBalanceStyles}>0</TableCell> */}
            </TableRow>
            <TableRow sx={modalBoxTableRowStyles}>
              <TableCell>Bridge Fee</TableCell>
              <TableCell sx={modalBoxBalanceStyles}>{formatUsdcBalance(bridgeFee, USDC_DECIMALS)}</TableCell>
            </TableRow>
            { isChainEnvFromPolygon(chainEnvFrom) && (
            <TableRow sx={modalBoxTableRowStyles}>
              <TableCell>Bridge Bonus</TableCell>
              <TableCell sx={modalBoxBalanceStyles}>{formatUsdcBalance(bridgeBonus, USDC_DECIMALS)}</TableCell>
            </TableRow>
            )}
            <TableRow sx={modalBoxTableRowStyles}>
              <TableCell>You will receive</TableCell>
              <TableCell sx={bridgeTransferAmount >= 0 ? modalBoxBalanceStyles : modalBoxBalanceNegativeStyles}>{formatUsdcBalance(bridgeTransferAmount, USDC_DECIMALS)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Modal>
    </Box>
  )
}

export default Bridge