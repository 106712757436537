import React, {
  useState,
} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Container,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ExpandMore,
} from '@mui/icons-material';

const items = [
  {
    question: 'What is the Rails Network launchpad?',
    answer: ` The Rails Network Launchpad is part of Steam Exchange, designed to provide a safe and secure platform for trading. We carefully select a limited number of innovative and reliable projects to launch on this network. This means you'll only find legitimate projects with practical applications here, making it a more trustworthy place to invest your money. Unlike other platforms, we don't overcrowd our launchpad with too many projects, ensuring quality over quantity.`,
  },
  {
    question: 'Is the Steam Exchange teams identity public?',
    answer: `Yes, Steam Exchange is a legally registered corporation in Ontario, Canada. Our team includes co-CEOs Chris Beckford, responsible for Infrastructure, and Neville Divecha, responsible for Operations. Steam Exchange is a corporation registered in Ontario, Canada. Registration# 002846823
Steam Exchange is registered as a Money Service Business (MSB), is Fintrac compliant, & has a long standing DUNS and Bradstreet profile.`,
  },
  {
    question: 'What about Partnerships?',
    answer: `Steam Exchange is proudly partnered with the University of Waterloo - Stratford School of Interaction Design & Business. The partership is specifically a research partnership which is tailored around Digital Assets and its adoption. Together with Sheridan College, we strive to develop an AI Driven Gamified Learning experience for digital assets.`,
  },
  {
    question: 'What wallet type does Rails Network support?',
    answer: `The Rails Network is compatible with various EVM (Ethereum Virtual Machine) compatible wallets. This means wallets like MetaMask and TokenPocket will work seamlessly with it. We're also working towards compatibility with TrustWallet and others, which will follow soon. If you're using a wallet that doesn't currently support custom RPC like TrustWallet, don't worry, your coins will still be safe and accessible.`,
  },
  {
    question: 'Is the blockchain public or private?',
    answer: `Rails Network is a combination of both. A public blockchain with a unique one-of-a-kind private consensus mechanism. Proof of Work by Authority or as we like to call it (PoWbA). This consensus mechanism prevents developers from deploying tools and applications to our network before being vetted by the core team. This approach prevents common scams and bad actors from exploiting the Steam Exchange community, and the broader Rails Network community.`,
  },
  {
    question: 'What is the SteamX network?',
    answer: `The SteamX Network, also known as the Rails Network, is being designed to be one of the fastest and most cost-effective networks available. It's still in testing but shows promise for high performance. Additionally, being a private network, it offers enhanced security, protecting investors from scams and ensuring a safer investment environment.`,
  },
  {
    question: 'What is the initial and Total supply?',
    answer: `The initial supply is set at ~165m coins, with a conversion ratio of 550:1 compared to our BSC token. As the network grows and miners join, new coins will be introduced, but they won't be in circulation until miners earn and release them. The mining process is crucial for our network's operation and growth.`,
  },
  {
    question: 'Why should we reward the miners?',
    answer: `Miners are vital for the performance and competitiveness of our blockchain. They contribute their computing power, which helps keep the network fast and cost-effective. Fairly rewarding miners attracts them to our network, boosting its functionality and value. This benefits both investors and miners, ensuring a thriving ecosystem.`,
  },
  {
    question: 'Is SteamX a token?',
    answer: `STEAMX is not a token, it is the Native Coin of Rails Network.`,
  },
];

const Faq = () => {

  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const containerStyles = {
    py: 4,
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    alignItems: 'center',
    bgcolor: 'transparent',
  };

  const boxStyles = {
    width: '100%',
    bgcolor: theme.palette.background.alt,
    borderRadius: 2,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    p: 2,
    mt: -6,
    color: theme.palette.steamx.white,
  }

  return (
    <Container maxWidth="sm" sx={containerStyles}>
      <Box sx={boxStyles}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', color: theme.palette.steamx.white }}>
          Frequently Asked Questions
        </Typography>
        <Typography variant="h6" sx={{ mb: 3, textAlign: 'center', color: theme.palette.steamx.white }}>
          Learn more about the Rails Network Ecosystem and the Depot
        </Typography>
        {items.map((faq, index) => (
          <Accordion 
            key={index}
            expanded={expanded === `panel-${index}`}
            onChange={handleChange(`panel-${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: theme.palette.steamx.white }} />}
              aria-controls={`panel-${index}-a-content`}
              id={`panel-${index}-a-header`}
            >
              <Typography sx={{ color: theme.palette.steamx.white }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: theme.palette.steamx.white }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default Faq;
