import React from 'react';

// import { ComingSoon } from 'components/ComingSoon';
import MigrateComponent from 'components/Migrate';

const Migrate = () => {
  return (
    // <ComingSoon />
    <MigrateComponent />
  );
};

export default Migrate;
