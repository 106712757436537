import React, {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Box,
  useTheme,
} from "@mui/material";

const Apply = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const theme = useTheme();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const isSmallScreen = windowWidth < 768;
  

  const defaultButtonStyle = {
    backgroundColor: theme.palette.steamx.orange,
    color: theme.palette.steamx.white,
    border: 'none',
    borderRadius: '6px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    margin: '0 0.5em',
    padding: '10px 20px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.steamx.light,
    }
  }

  const buttonStyle = Object.assign({}, defaultButtonStyle, {
    margin: '10px',
    fontSize: '14px',
  });

  const closeButtonStyle = Object.assign({}, defaultButtonStyle, {
    margin: '10px auto', // Center the close button horizontally
    fontSize: '18px',
  });

  const defaultBoxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const modalContainerStyle = Object.assign({}, defaultBoxStyle, {
    position: 'fixed',
    top: '0',
    left: isSmallScreen ? '0' : '-20%',
    width: isSmallScreen ? '100%' : '120%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1000',
  });

  const innerModalStyle = Object.assign({}, defaultBoxStyle, {
    background: 'none',
    boxShadow: 'none',
    width: isSmallScreen ? '100%' : '200%', // Default width for larger screens
    height: '600px', // Default height for larger screens
    flexDirection: 'column',
  });

  const iframeStyle = {
    width: isSmallScreen ? '125%' : '150%',
    height: isSmallScreen ? '100%' : '125%',
    border: 'none',
    borderRadius: '8px',
  };

  // Media query for smaller screens
  const smallerScreens = '@media (maxWidth: 768px)';

  return (
    <>
      <Box sx={Object.assign({}, defaultBoxStyle, { height: '100vh'})}>
        <Button onClick={openModal} sx={buttonStyle}>
          Open Application Form
        </Button>
      </Box>

      {isModalOpen && (
        <Box sx={modalContainerStyle} onClick={closeModal}>
          <Box className="modal-content" onClick={(e) => e.stopPropagation()}>
            <Box sx={{ ...innerModalStyle, [smallerScreens]: { width: '85%', height: '85%' } }}>
              <iframe
                title="Basic Application Form"
                allowFullScreen={true}
                allow="geolocation; microphone; camera"
                src="https://form.jotform.com/233264447089059"
                frameBorder="0"
                scrolling="no"
                style={iframeStyle}
              />
              <Button sx={closeButtonStyle} onClick={closeModal}>
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Apply;
