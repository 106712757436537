import React from 'react';
import { Outlet } from "react-router-dom";
import { 
  Box,
} from "@mui/material";
import Sidebar from 'components/Sidebar';

const Layout = () => {

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar drawerWidth={240} />
      <Box component="main" width={1}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default Layout