import React from 'react';

import { ComingSoon } from 'components/ComingSoon';

const Presale = () => {
  return (
    <ComingSoon />
  );
};

export default Presale;
