import React, {
} from "react";
import {
  Alert,
  Collapse,
  IconButton,
} from "@mui/material";
import {
  Close,
} from "@mui/icons-material";
import PropTypes from 'prop-types';
import { isMobileMediaQuery } from 'helpers';

const CollapsibleAlert = (props) => {

  const { alertSeverity, alertText, isAlertDisplayed, setIsAlertDisplayed } = props;
  const isMobile = isMobileMediaQuery();
  
  const alertStyleMobile = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& .MuiAlert-icon': {
      paddingLeft: "10px",
    }
  };
  const alertStyle = Object.assign({}, alertStyleMobile, {
    '& .MuiAlert-icon': {
      flex: 1,
      justifyContent: "end",
    },
    '& .MuiAlert-message': {
      flex: 1,
      flexGrow: 2,
    },
    '& .MuiAlert-action': {
      flex: 1,
    },
  });

  return (
    <Collapse in={isAlertDisplayed}>
      <Alert severity={alertSeverity} sx={isMobile ? alertStyleMobile : alertStyle}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setIsAlertDisplayed(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        {alertText}
      </Alert>
    </Collapse>
  )
}

CollapsibleAlert.propTypes = {
  alertSeverity: PropTypes.string,
  alertText: PropTypes.string,
  isAlertDisplayed: PropTypes.bool.isRequired,
  setIsAlertDisplayed: PropTypes.func.isRequired, 
};

export { CollapsibleAlert };